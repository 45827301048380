$shadow: 10px 10px 20px 0 #A6B4C8B2, -12px -12px 20px 0 #FFFFFFCC;

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  left: -10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

.h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
}

.h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

* {
  outline: none;
}

#navBar {
  height: 73px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: $shadow;
  padding: 29px 0 24px 0;
  border-radius: 10px;
  border: 0.5px solid;
  border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logoBlock {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
  }

  .logo {
    width: 32px;
    height: 37px;
    margin: 0 91px 0 15px;
  }

  .buttonsBlock {
    display: flex;
    align-items: center;

    .icon {
      path {
        fill: #15274B

      }
    }
  }

  .buttonsBlock {
    .icon-heart {
      svg path {
        fill: #F40000;
      }
    }
  }

  .menu {
    list-style-type: none;
    display: flex;

    li {
      margin-right: 30px;

      a {
        text-decoration: none;
        color: #1C3B6A;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .buttons {
    display: flex;
    margin-right: 14px;

    .icon {
      border-radius: 50%;
      //margin-right: 19px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.container {
  max-width: 1200px;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

#settingsDropdown {
  max-width: 165px;
  position: relative;

  .user {
    display: flex;
    align-items: center;
    height: 53px;
    box-shadow: $shadow;
    background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
    border-radius: 10px;
    padding: 9px 13px;
    z-index: 5;
    position: relative;

    img {
      width: 30px;
      height: 28px;
      margin-right: 6px;
      border-radius: 3px;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }

    .setting {
      cursor: pointer;
      line-height: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 5px;

      span {
        font-size: 10px;
        font-weight: 400;
        margin-right: 8px;
      }
    }
  }

  .dropdown {
    list-style-type: none;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    padding: 32px 12px 20px 12px;
    width: 184px;
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 3;

    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #1C3B6A;
    }
  }
}

#wallets {
  display: flex;
  margin-right: 0;
  margin-left: 0;

  .slider-row {
    display: flex;
    padding: 30px 0;

    .walletType {
      width: 387px;
      min-width: 387px;
    }

  }

  &.slider {
    width: 100%;
    overflow: visible;
    position: relative;
    border-radius: 20px;

    .walletType {
      width: 360px;
      min-width: 360px;
    }

    &:after {
      content: "";
      width: 10px;
      height: 147px;
      box-shadow: 10px 20px 20px 0 #D8D8D8, 10px -10px 20px 0 #EFF1F3, 10px -10px 20px 0 #F2F4F6;
      top: 50px;
      right: 0;

      z-index: -1;
      position: absolute;
    }

    &:before {
      content: "";
      width: 10px;
      height: 147px;
      box-shadow: -10px 10px 20px 0 rgb(215 222 230), -10px -10px 20px 0 rgb(255 255 255), -10px -10px 20px 0 rgb(255 255 255);
      top: 50px;
      z-index: -1;
      left: 0;
      position: absolute;
    }

    .slider-row {
      overflow-x: auto;
      display: flex;
      padding: 50px 0;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        left: 0;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }
  }
}

.walletType {

  height: 147px;
  background: #2877EE;
  box-shadow: $shadow;
  border-radius: 10px;
  padding: 16px 20px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    color: #FFFFFF;

    svg {
      margin-right: 5px;
    }
  }


  .items {
    display: flex;
    align-items: center;
    margin-top: 18px;

    .item {
      margin-right: 30px;

      div {
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 8px;

        path {
          fill: #FFFFFF;
        }
      }

      span {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .amount {
      padding: 7px 10px;
      color: #FFFFFF;
      background: #2965BE;
      box-shadow: 10px 10px 20px 0 #0C55C2;
      font-size: 14px;
      font-weight: 600;
      border-radius: 10px;
      margin-top: 15px;
      min-width: 85px;

    }
  }
}

.home, .singleProduct, .catalog, .addToCard, .addPaymentMethod, .paymentMethods {
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
  }

}


#wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@keyframes scale {
  to {
    transform: scale(1.1);
  }
}

.productItem {
  width: 285px;
  margin: 0 7px 20px;
  box-shadow: 10px 10px 20px 0 #FF83381A, -12px -12px 20px 0 #FFFFFF80;
  border-radius: 10px;

  &:hover {
    box-shadow: 10px 10px 30px 0 #FF833845, -12px -12px 20px 0 #FFFFFFCC;
  }

  .bottom {
    padding: 0 20px 24px 20px;
    background: #E9EDF0;
    border-radius: 10px;
  }

  .top {
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
    position: relative;
    padding: 20px 20px 14px 16px;
    background: #FFFFFF;
    border-radius: 10px 10px 0 0;

    .imgContainer {
      width: 150px;
      height: 160px;
      margin: 0 auto;

      &:hover {
        img {
          transform: scale(1.15);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all 400ms ease;
      }
    }


    .icon {
      position: relative;
      top: -16px;
      left: 19px;
      cursor: pointer;

      path {
        fill: #F40000;
      }
    }
  }

  a {
    text-decoration: none
  }

  .itemInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: -10px;

    .iconsBlock {
      display: flex;
      align-items: center;
      justify-content: center;

      .iconRenewals {
        cursor: pointer;

        > svg {
          position: relative;
          top: -16px;
          left: 8px;
        }
      }
    }

    .number {
      cursor: pointer;

    }

    .icon {
      margin-right: 7px;

    }

    p {
      font-size: 14px;
      color: #1C3B6A;
      font-weight: 600;

    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #1C3B6A;
    margin-bottom: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 244px;

    span {
      width: 100%;
    }
  }

  .tablets {
    font-size: 14px;
    color: #1C3B6A;
    font-weight: 400;
    margin-bottom: 7px;
  }

  .price {
    color: rgba(255, 95, 0, 0.81);
    font-size: 20px;
    font-weight: 600;
  }

  .addToCardContainer {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 9px;

      path {
        fill: #FFFFFF;
      }
    }

    .btn {
      height: 51px;
      width: 136px;
      font-size: 13px;
      font-weight: 600;
      background: transparent;
      color: #2877EE;
      box-shadow: unset;
      border: 1px solid #2877EE;
      border-radius: 5px;

      svg {
        path {
          fill: #2877EE;
        }
      }

      &:hover {
        background: #2877EE;
        color: #FFFFFF;
        box-shadow: 10px 10px 20px 0 #A6B4C8B2, -12px -12px 20px 0 #FFFFFFCC;

        svg {
          path {
            fill: #FFFFFF;
          }
        }
      }
    }


  }
}

.topProducts, .relatedProducts {
  width: 100%;
  margin-top: 32px;

  .blockTitle {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .small {
      margin-right: 8px;
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

.qtyPicker {
  user-select: none;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    outline: none;
    height: 51px;
    width: 57px;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF1F5 4.98%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(306.85deg, #FEFEFF 12.98%, #C6CEDA 91.82%);
    box-shadow: 5px 5px 10px 0 #A6B4C8BF inset, -5px -5px 15px 0 #FFFFFFBF inset;
    //padding: 7px 0 7px 23px;
    text-align: center;
    color: #1C3B6A;
    font-size: 14px;
    font-weight: 600;
  }

  .symbol {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    &.minus {
      margin-right: 6px;
    }

    &.plus {
      margin-left: 6px;
      margin-right: 14px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.pagination {
  width: 100%;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: 10px 10px 20px 0 rgb(166 180 200 / 70%), -12px -12px 20px 0 rgb(255 255 255 / 80%);
  border-radius: 10px;
  height: 60px;
  display: flex;
  align-items: center;

  ul {
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: center;

    li {
      font-size: 14px;
      font-weight: 600;
      margin-left: 14px;
      cursor: pointer;
      height: 36px;
      width: 36px;
      color: #15274B;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active {
        background-color: #2877EE;
        border-radius: 8px;
        color: #FFFFFF;
      }
    }
  }

  .icon {
    path {
      fill: #15274B;
    }
  }
}

.checkbox {

  input {
    padding: 0;
    height: 100%;
    width: 100%;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;

  }

  label:before {
    content: '';
    -webkit-appearance: none;
    padding: 10px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: 1px 1px 6px 0 #1C3B6A80 inset;
    border-radius: 3px;

  }


  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #E6E9EF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-6px, -5px);
  }

  input:checked + label:before {
    background: #2877EE;
    box-shadow: unset;

  }
}

.singleProductItem {
  width: 100%;
  display: flex;
  margin-top: 94px;
  position: relative;

  .infoBlock {
    color: #1C3B6A;
    //max-width: 323px;
    width: 49%;

    .icon-button-heart {
      cursor: pointer;
    }

    .number {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }

    .h2 {
      max-width: 500px;
    }

    .title {
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
    }

    .tablets {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }

    .price {
      font-size: 30px;
      font-weight: 600;
      line-height: 50px;
      color: rgba(255, 95, 0, 0.81);
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 10px;
    }

    .dose {
      .checkbox {
        margin-bottom: 10px;
      }

    }

    .addToCardContainer {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .icon {
        margin-right: 9px;

        path {
          fill: #FFFFFF;
        }
      }

      .btn {
        height: 51px;
        width: 149px;
        font-size: 13px;
        font-weight: 600;
        margin-right: 20px;
      }


    }
  }

  .icon.heart {
    position: absolute;
    right: 0;
    top: 0;

    svg path {
      fill: #F40000;
    }
  }
}

.descriptionContainer {
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  margin-top: 55px;
  border: 0.5px solid;
  border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
  box-shadow: $shadow;
  padding: 20px 75px 20px 30px;

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .description {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
  }
}

.slider {
  overflow: hidden;
  //padding: 26px 0;
  margin-left: -28px;
  margin-right: -28px;
  width: calc(100% + 56px);

  .products {
    display: flex !important;
    flex-wrap: nowrap;
    margin: 0 18px;
  }

  .slick-dots {
    width: 100%;
    margin-top: 31px;
    position: static;

    li {
      button {
        width: 14px;
        height: 14px;
        border-radius: 40px;
        box-shadow: $shadow;
        margin-right: 14px;
        background: linear-gradient(134.17deg, #FAFAFA 4.98%, #E6E9EF 94.88%);
        border: 0.5px solid;
        border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);

        &:before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background: #00758B;
        }
      }

    }
  }


  .slick-initialized .slick-slide {
    > div {
      display: flex;
      width: 100%;
    }

    //
    //.productItem {
    //  width: 25%;
    //  margin: 0 10px;
    //}
  }

  //.slick-list {
  //  overflow: visible;
  //}
}

.filterByPrice {
  width: 100%;

  > p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 14px;
  }

  .range {
    height: 76px;
    border-radius: 10px;
    background: linear-gradient(285.71deg, #FEFEFF 20.5%, #EEF0F5 74.07%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    padding: 17px 18px 17px 21px;
    display: flex;
    align-items: center;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    input {
      outline: none;
      height: 40px;
      width: 60px;
      border-radius: 10px;
      background: linear-gradient(134.17deg, #EEF1F5 4.98%, #E6E9EF 94.88%);
      border: 0.5px solid;
      border-image-source: linear-gradient(306.85deg, #FEFEFF 12.98%, #C6CEDA 91.82%);
      box-shadow: 5px 5px 10px 0 #A6B4C8BF inset, -5px -5px 15px 0 #FFFFFFBF inset;
      padding: 7px 18px 7px 7px;
      color: #1C3B6A;
      font-size: 14px;
      font-weight: 600;

    }

    .input-icon {
      top: 5px;
      right: 5px;

      svg {
        path {
          fill: #1C3B6A;
        }
      }
    }

  }

}

.rc-slider {
  margin: 0 12px;
  height: 24px;
  padding: 10px 0;

  .rc-slider-rail {
    height: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.81));
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;

  }

  .rc-slider-track {
    height: 8px;
    opacity: 1;
    background: #FF8338;
  }

  .rc-slider-handle {
    height: 18px;
    width: 18px;
    border-radius: 40px;
    background: #FF8338;
    //background: linear-gradient(134.17deg, #FFFFFF 4.98%, #E8FBFF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    opacity: 1;

    &:hover, &:focus {
      box-shadow: $shadow;

    }

  }

  .rc-slider-handle-dragging {
    border-color: transparent !important;
    box-shadow: unset !important;

  }
}

.loaderContainer.big {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.catalog {


  .shopByCategories {
    width: 100%;

    .showingText {
      display: flex;

      .small {
        margin-left: 10px;
      }
    }

    .filterOptions {
      display: flex;
      align-items: flex-end;
    }

    .filterByPrice {
      width: 422px;
      margin-right: 17px;
    }

    .catalogSelect {
      width: 170px;

      svg path {
        fill: #15274B;
      }

    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    font-size: 20px;
    font-weight: 600;
    line-height: 30px;

  }

  .categoriesContainer {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .filters {
      width: 23.8%;
      //height: 1288px;
      border-radius: 10px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      box-shadow: $shadow;
      padding: 22px 13px 22px 16px;
      margin-bottom: 20px;

      .icons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          align-items: center;

          .icon {
            margin-right: 12px;
          }

          span {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
          }
        }
      }

      .types {
        display: flex;
        margin: 17px 0 22px 0;
        align-items: center;

        .checkbox {
          margin-right: 26px;
        }

        .label {
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
          }

          span {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;


          }
        }
      }
    }

    .products {
      width: 75%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;
      align-content: baseline;
    }

    .fsa-checkbox {
      margin-top: 20px;
    }
  }

  .checkbox label:before {
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: inset 1px 1px 6px rgba(28, 59, 106, 0.5);
  }
}

.catalogSelect {
  .spSelect {


    .sp__control {
      height: 76px;
      width: 100%;
      border-radius: 10px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      box-shadow: $shadow;
      cursor: pointer;
      padding: 15px 17px;
      color: #1C3B6A;
    }

    .sp__option {
      font-size: 14px;
    }
  }

  .sp__option--is-selected {
    background: #FF8338;
  }

  &.filterSelect {
    height: 60px;

    svg path {
      fill: #15274B;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

}


.addToCard {
  .shoppingCartContainer {
    width: 100%;
    margin-top: 30px;
    display: flex;
  }

  .shoppingCart {
    width: 66%;
  }

  .shoppingCartContainer {
    //width: 64%;
    .actions {
      .icon {
        cursor: pointer;
      }
    }

    .top {

      display: flex;
      justify-content: space-between;

      p {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  .infoTable .body .details .type {
    margin-top: 12px;
  }

  .orderSummary {
    width: 32.4%;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    margin-top: 15px;
    padding: 25px 30px 26px 30px;
    height: 282px;

    .item {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #1C3B6A;
      }

      .balance {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: #1C3B6A;

      }
    }

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 15px;
    }


    .action {
      display: flex;
      justify-content: center;
    }

    .btn {
      margin-top: 23px;
      height: 60px;
      width: 100%;
      border-radius: 10px;
      padding: 10px 50px 10px 50px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
  }
}

.emptyCart {
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .basketIcon {
    path {
      fill: #7F8A9E;
    }

    margin-bottom: 25px;
  }

  .title {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    color: #15274B;
    margin-bottom: 11px;
  }

  .description {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #15274B;
    margin-bottom: 25px;
  }

  .btn {
    height: 60px;
    width: 296px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
}

.back {
  display: flex;
  margin-top: 40px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  > p {
    color: #2877EE;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
}

.infoTable {
  width: 100%;
  margin-top: 15px;

  .table {
    width: 100%;
    border-spacing: 0 15px;
  }

  .head {
    display: flex;
    align-items: center;
    height: 54px;
    border-radius: 10px;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;

    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #2877EE;

    .details {
      text-align: center;
      padding-left: 20px;
      width: 47%;
      //width: 365px;
      //min-width: 365px;
      //max-width: 365px;

    }

    .qty {
      //width: 130px;
      //min-width: 130px;
      //max-width: 130px;
      width: 12%;
      text-align: center;
    }

    .price {
      //width: 90px;
      //min-width: 90px;
      width: 19%;
    }

    .total {
      //width: 90px;
      //min-width: 90px;
      width: 8%;
    }

    .price {
      text-align: center;
    }

    .total {
      text-align: right;
    }

    .actions {
      //min-width: 80px;
      width: 9%;
    }
  }

  .body {
    .disabledStock {
      position: absolute;
      color: red;
      font-size: 18px;
      transform: rotate(343deg);
    }

    .row {
      height: 100px;
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      box-shadow: $shadow;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      border-radius: 10px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      padding: 20px 14px;
    }

    .productCheckbox {
      display: flex;
      align-items: center;
    }

    .details {
      padding: 6px 7px 17px 7px;
      width: 47%;
      //width: 365px;
      //min-width: 365px;
      //max-width: 365px;

      > div {
        display: flex;
      }

      .productImg {
        display: flex;
        align-items: center;
        width: 100px;
        min-width: 100px;
        justify-content: center;

        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
      }

      .info {
        a {
          text-decoration: none;
          color: #1C3B6A;
        }

        .title {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 1px;
          margin-top: 8px;
        }

        .number {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin-bottom: 1px;

        }

        .variants {
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          display: flex;
          justify-content: space-between;
        }

        .enough__stock {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          color: red;
        }
      }
    }

    .qty {
      width: 19%;
      //width: 130px;
      //min-width: 130px;
      //max-width: 130px;
      //text-align: center;
    }

    .actions {
      margin: auto;
      width: 3%;

      .icon {
        border-radius: 40px;
      }
    }

    .price, .total {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      //width: 90px;
      //min-width: 90px;w

    }

    .price {
      width: 12%;
    }

    .total {
      width: 11%;
    }

    .total {
      color: #EB001B;
      text-align: right;
    }
  }

}


.menuSelects {
  .select-block .select-container {
    display: flex;
    justify-content: space-between;
  }

  .select-container {
    height: 58px;
    margin-bottom: 56px;
  }

  .sp__single-value {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
  }

  .first-select .sp__single-value {
    color: #FFFFFF;
  }

  .sp__control {
    width: 284px;
    position: relative;
    z-index: 5;

    .sp__value-container {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }


    svg {
      position: absolute;
      right: 25px;
    }

    .sp__value-container--has-value {
      background: #00758B;

      .sp__placeholder {
        color: white;

      }

      .sp__single-value {
        color: white;

      }
    }
  }

  .sp__menu {
    position: relative;
    top: -10px;
  }


  .sp__placeholder {
    font-size: 18px;
  }

  .first-select .sp__placeholder {
    color: #FFFFFF;
  }


  .sp__menu-list {
    background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
    border-radius: 10px;
  }


  .sp__option--is-focused {
    background: transparent;
  }

  .sp__option:hover, .sp__option--is-selected {
    color: #FFFFFF;
    background: #FF5F00;
  }

}

.orderHistory {
  .menuSelects {
    width: 100%;
    margin-top: 47px;
  }

  .infoTable {
    .row {
      &.titles {
        height: 58px;
        color: #2877EE;
      }

      > div {
        display: flex;
        //justify-content: center;
        align-items: center;
      }

      .img {
        width: 41%;

        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
      }
    }

    .number, .tablets, .dose, .date, .title, .price, .qty, .img {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }

    .mobile-number-image-block {
      width: 25%;
      min-width: 25%;
      max-width: 25%;

      a {
        text-decoration: none;
        color: #1C3B6A;
      }
    }

    .number {
      width: 59%;
    }

    .title-tablets-dose-date-price {
      width: 60%;
      min-width: 60%;
      max-width: 60%;
    }

    .title {
      width: 30%;
      margin-right: 15px;
    }

    .tablet-dose {
      display: flex;
      width: 35%;
    }

    .dose {
      width: 14%;
    }

    .date {
      width: 17%;
    }

    .price, .qty {
      width: 4%;
      margin-right: 15px;
    }

    .download-receipt {
      width: 18%;
    }

    .download-receipt .btn {
      width: 131px;
      height: 48px;
      background: #0B8B00;
      color: #FFFFFF;
    }

    .tablets .tablets-comma {
      display: none;
    }

    .dots {
      width: 2%;
      cursor: pointer;

      svg {
        path {
          fill: #09121F;
        }
      }
    }
  }

  .secondDatepicker {
    left: -56px !important;
  }
}

.datepicker {
  position: relative;

}

.selectArrow {
  &.whiteArrow {
    path {
      fill: white;
    }
  }
}


.react-datepicker {
  border-radius: 10px !important;
  border: none !important;
  box-shadow: $shadow;

  .react-datepicker__day--selected {
    background-color: #1C3B6A;
    border: none;
    outline: none;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;

  }

  .react-datepicker__header {
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  }

}

.react-datepicker-popper {
  z-index: 10 !important;
}

.addPaymentMethodWrapper {
  height: 251px;
  width: 387px;
  border-radius: 20px;
  background-image: url("../images/card-background.svg");
  box-shadow: 0 4px 4px 0 #A6B4C8B2;
  border: 1px solid #FFFFFF;
  background-size: cover;
  padding: 18px 17px 18px 19px;

  &.front {
    margin-right: 20px;
  }

  .cardInfo {
    display: flex;
    justify-content: space-between;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  .bankName {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .cardInput {
    height: 39px;
    width: 230px;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF1F5 4.98%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(306.85deg, #FEFEFF 12.98%, #C6CEDA 91.82%);
    box-shadow: 5px 5px 10px 0px #A6B4C8BF inset, -5px -5px 15px 0px #FFFFFFBF inset;
    display: flex;
    padding: 0 20px 0 25px;
    //align-items: center;
    .StripeElement {
      width: 100%;
    }

    &.expiry {
      width: 115px;
    }
  }

  .cardContainer {
    display: flex;
    align-items: center;

    svg {
      margin-left: 15px;

    }
  }

  .strip {
    height: 45px;
    background: #1C3B6A;
    margin: 18px -17px 18px -19px;
  }

  .cvcContainer {
    margin: 0 10px;
    position: relative;

    img {
      width: 100%;

    }

    .cvc {
      position: absolute;
      right: 8px;
      top: 3px;
      font-size: 14px;
      font-style: italic;
      font-weight: 700;
      line-height: 21px;
      color: #1C3B6A;
      filter: blur(2px);
    }
  }

  .cardElement {
    .cvc {
      width: 84px;
    }
  }
}

.addPaymentMethod, .paymentMethods {
  .addPaymentMethodContainer {
    width: 100%;

    .h2 {
      margin: 38px 0;

    }
  }

  .termsBlock {
    margin: 40px 0 35px 0;

    label {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
  }

  .actions {
    display: flex;
    margin-bottom: 134px;

    .btn {
      height: 75px;
      width: 386px;
      font-size: 20px;
      font-weight: 600;
      line-height: 50px;
      color: #FFFFFF;
      cursor: pointer;

      &.save {
        margin-right: 20px;
      }

      &.cancel {
        background: linear-gradient(176.28deg, #EEF0F5 76.11%, rgba(198, 206, 218, 0) 230.21%);
        border: 0.5px solid;
        border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
        width: 279px;
        color: #15274B;
      }
    }
  }
}

.paymentMethodApp {
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .cardInput {
    box-shadow: unset;
    background: linear-gradient(0deg, #E9EDF0, #E9EDF0), linear-gradient(0deg, #B8C1D2, #B8C1D2);
    border: 1px solid #B8C1D2
  }

  .checkbox {
    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .actions {
    .btn {
      box-shadow: unset;
      font-size: 14px;
    }

    .cancel {
      border: 1px solid #B8C1D2 !important;
    }
  }

  .addPaymentMethodWrapper {
    box-shadow: unset;
  }

}

.paymentMethods {
  .cards {
    display: flex;
    flex-wrap: wrap;
  }
}

.cardTypes {
  margin-bottom: 43px;
  display: flex;
  flex-wrap: wrap;

  .cardType {
    width: 62px;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;
    background: #FFFFFF33;
    border: 1px solid #FFFFFF;
    box-shadow: 0 4px 4px 0 #A6B4C8B2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .checkFilled {
    position: absolute;
    top: -6px;
    right: -6px;

  }
}

.card {
  height: 192px;
  width: 297px;
  background: #FFFFFF33;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  box-shadow: 0 4px 4px 0 #A6B4C8B2;
  padding: 12px 15px 8px 18px;
  margin-right: 30px;
  margin-bottom: 30px;

  .cardInfo {
    display: flex;
    justify-content: space-between;
  }

  .bankName {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .cardNumber {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #1C3B6A;
    //padding: 0 19px;
    opacity: 0.5;

    span {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .expiry {
    color: #1C3B6A;
    display: flex;
    //justify-content: center;
    width: 100%;
    align-items: flex-start;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 5px;

      span {
        font-size: 5px;
        font-weight: 600;
        line-height: 8px;
      }

    }

    .date {
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;

      }
    }
  }

  .actions {

    display: flex;
    justify-content: flex-end;

    > div {
      height: 34px;
      width: 35px;
      border-radius: 10px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      box-shadow: $shadow;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      svg {
        cursor: pointer;
      }
    }

  }

}

.addNewCard {
  height: 192px;
  width: 297px;
  border-radius: 20px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  border: 0.5px solid;
  border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: #1C3B6A;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
}

.globalSearch {
  width: 100%;
  position: relative;

  .loaderContainer {
    position: absolute;
    right: 38px;
    top: 14px;

    .loader {
      width: 20px;
      height: 20px;
    }
  }

  .searchInput {
    height: 53px;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF1F5 4.98%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(306.85deg, #FEFEFF 12.98%, #C6CEDA 91.82%);
    box-shadow: 5px 5px 10px 0 #A6B4C8BF inset, -5px -5px 15px 0 #FFFFFFBF inset;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #15274B;
    padding: 14px 21px 14px 57px;

    &::placeholder {
      opacity: 0.5;
    }

  }

  .searchIcon {
    position: absolute;
    left: 20px;
    top: 18px;
  }

  .deleteIcon {
    position: absolute;
    right: 20px;
    top: 18px;
    cursor: pointer;
  }

  .results {
    width: 100%;
    border-radius: 20px;
    position: absolute;
    background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    z-index: 20;
    padding: 22px 57px;

    > ul {
      overflow-y: auto;
      max-height: 400px;

    }

    li {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 13px;
      color: #15274B;
      display: flex;

      a {
        text-decoration: none;
        color: #15274B;
        font-size: 18px;
        outline: none;
        width: 100%;
      }
    }

    .recent {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      color: #9D9D9D;

    }
  }

}

.loader {
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid #00758B;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sign-up-third, .log-in {
  .icon {
    cursor: pointer;
  }

  .close {
    path {
      fill: #bbc0cd;
    }
  }
}

.wrapperLoader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoriesSelectButton {
  height: 54px;
  width: 203px;
  border-radius: 10px;
  background: #FF8338;
  box-shadow: 10px 10px 20px 0 #A6B4C8B2, -12px -12px 20px 0px #FFFFFFCC;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  position: relative;

  svg {
    margin-left: 6px;
    transition: .2s;

    path {
      fill: white;

    }
  }

  &.open {
    svg {
      transform: rotate(180deg);

    }
  }

  .selectedCatCount {
    width: 25px;
    height: 25px;
    background: white;
    position: absolute;
    right: -6px;
    top: -6px;
    color: #FF8338;
    border-radius: 50%;
    box-shadow: 10px 10px 20px 0px #A6B4C8B2, -12px -12px 20px 0px #FFFFFFCC;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

.categoriesSelect {
  position: absolute;
  left: 0;
  top: 60px;
  border-radius: 10px;

  overflow: hidden;
  z-index: 10;
  box-shadow: 10px 10px 20px 0 #A6B4C8B2, -12px -12px 20px 0 #FFFFFFCC;
  background: #E9EDF0;
  padding: 44px 20px 90px 20px;

  .action {
    position: absolute;
    bottom: 23px;
    left: 58px;

    .btn {
      height: 48px;
      width: 149px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      box-shadow: unset;

    }
  }

  .categoriesSelectContainer {
    border-radius: 10px;
    max-height: 458px;
    display: flex;
    transition: width 500ms;
    width: 400px;

    &.more {
      width: 800px;

    }

    .catName {
      display: flex;
      align-items: center;
    }

    .childCount {
      width: 17px;
      height: 17px;
      background: #2877EE;
      color: white;
      border-radius: 50%;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    > div {
      //width: 33%;
      min-width: 400px;
      overflow-y: auto;
    }

    .row {
      padding: 0 40px;

      .category, .subcategory {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #15274B;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: .3ms;

        .arrowContainer {
          width: 20px;
          height: 20px;
          transition: .2ms;
        }

        .arrow {
          height: 10px;
          transform: rotate(90deg);

          &.open {
            transform: rotate(0);

            path {
              fill: #2877EE;
            }
          }
        }

        &.active {
          color: #2877EE;
        }

        &.selected {
          color: #2877EE;
        }
      }

      .category {
        font-weight: 600;
      }
    }

    .border {
      border-right: 1px solid #15274B;
      opacity: 0.2;
      position: absolute;
      top: 40px;
      height: calc(100% - 80px);
      left: 33%;

      &.border_1 {
        left: 66%;
      }
    }
  }

  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

}

.partlySelected {
  .checkbox {
    label {
      &:before {
        content: "";
        background-image: url("../icons/minus.svg");
        background-repeat: no-repeat;
        background-size: 15px 10px;
        background-position: center;
      }
    }
  }

}

.seeMore {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #2877EE;
  align-items: center;
  margin: 30px 0;
  padding-right: 7px;
  cursor: pointer;

  svg {
    margin-left: 4px;

    path {
      fill: #2877EE;
    }
  }
}

.highlight {
  color: #2877EE;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modalOverlay {
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000033;
}

.confirmationModal {
  width: 334px;
  border-radius: 10px;
  background: #FFFFFF;
  padding: 37px 47px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    margin-bottom: 21px;

  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 50px;
      width: 84px;
      border-radius: 10px;
      padding: 15px 30px 15px 30px;
      box-shadow: unset;

      &.btn-teal {
        margin-right: 7px;
      }

      &.btn-white {
        box-shadow: unset;
        border: 1px solid #2877EE;
        filter: unset;
        background: #FFFFFF;
        color: #2877EE;
      }
    }

  }
}

.renewalsDeleteModal {
  width: 445px;
  padding-bottom: 35px;
  background: #EEF0F5;
  border-radius: 20px;

  .title {
    padding-top: 45px;
    padding-left: 23px;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    color: #15274B;
  }

  .description {
    width: 430px;
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
    color: #15274B;
    padding-left: 23px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .btn {
      height: 50px;
      width: 84px;
      border-radius: 10px;
      padding: 15px 30px 15px 30px;
      box-shadow: unset;

      &.btn-teal {
        margin-right: 15px;
      }

      &.btn-white {
        box-shadow: unset;
        border: 1px solid #2877EE;
        filter: unset;
        background: #FFFFFF;
        color: #2877EE;
      }
    }
  }
}

.renewalsModal {
  border-radius: 20px;
  padding: 37px 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 451px;
  background: #EEF0F5;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    color: #15274B;
    margin-bottom: 30px;
  }

  .description {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #15274B;
    margin-bottom: 50px;

    > span {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #15274B;
      margin-bottom: 50px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 50px;
      width: 84px;
      border-radius: 10px;
      padding: 15px 30px 15px 30px;
      box-shadow: unset;

      &.btn-teal {
        margin-right: 15px;
      }

      &.btn-white {
        box-shadow: unset;
        border: 1px solid #2877EE;
        filter: unset;
        background: #FFFFFF;
        color: #2877EE;
      }
    }
  }
}

.emptyWishlist {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .heartIcon {
    path {
      fill: #7F8A9E;
    }

    margin-bottom: 25px;
  }

  .title {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    color: #15274B;
    margin-bottom: 9px;
  }

  .description {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #15274B;
  }

  .heartSmallIcon {
    height: 15px;
    width: 16px;
    margin-left: 5px;

    path {
      fill: #F40000;

    }
  }

  .descriptionCont {
    display: flex;
    align-items: center;

  }
}

.addToCartModal {
  width: 445px;
  border-radius: 10px;
  background: #FFFFFF;
  padding: 21px 21px 50px 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }

  .top {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 12px;

    .deleteIcon {
      width: 12px;
      height: 12px;
      cursor: pointer;

      path {
        fill: #15274B;
      }
    }
  }

  .content {
    padding-right: 29px;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    color: #15274B;
    margin-bottom: 5px;
  }

  .price {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #FF8338;
    margin-bottom: 18px;
  }

  .qty {
    display: flex;
    align-items: center;
    margin-bottom: 34px;

    span {
      margin-right: 15px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #15274B;

    }

    .qtyPicker {
      input {
        box-shadow: unset;
        border: 1px solid #B8C1D2;
        background: #E9EDF0;
        height: 35px;
        width: 70px;
        border-radius: 10px;

      }
    }
  }

  .spSelect {
    margin-bottom: 10px;

    .sp__control {
      height: 48px;
      border-radius: 10px;
      border: 1px solid #B8C1D2;
      background: #E9EDF0;
      cursor: pointer;

      &.sp__control--menu-is-open {
        .selectArrow {
          transform: rotate(180deg);
        }
      }
    }

    .sp__menu {
      border-radius: 10px;

    }

    .sp__single-value {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #15274B;
    }

    .sp__menu-list {
      border-radius: 10px;
      border: 1px solid #B8C1D2;
      background: #E9EDF0;
      padding: 17px 25px;

      .sp__option {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #15274B;
        cursor: pointer;
      }

      .sp__option--is-selected {
        font-weight: 600;
        background: transparent;
      }

      .sp__option--is-focused {
        background: transparent;
        font-weight: 600;

      }
    }
  }

  .btn {
    height: 48px;
    width: 345px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;

      path {
        fill: #FFFFFF;
      }
    }
  }
}

.bannerSlider {
  margin-top: 33px;


  .slick-list {
    margin-right: -10px;
    margin-left: -10px;
    width: calc(100% + 20px);
  }

  .slick-dots {
    bottom: 9px;

    li {
      margin: 0;
    }

    li button:before {
      color: #FFFFFF57 !important;
      font-size: 10px;
    }

    li.slick-active button:before {
      font-size: 10px;
      color: #FFFFFF !important;
    }
  }
}

.bannerSliderItemWrapper {
  padding: 0 10px;
}

.bannerSliderItem {
  width: 100%;
  height: 304px;
  border-radius: 10px;
  padding: 60px 33px 48px 80px;
  display: flex;

  .left {
    width: 61%;

    p {
      color: #ffffff;
    }

    .h2 {
      margin-bottom: 4px;
    }

    .desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 15px;
    }

    .btn {
      height: 60px;
      width: 185px;
      border-radius: 10px;
      background: #FF8338;
      box-shadow: unset;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }

  }

  .right {
    width: 39%;
  }
}

.backToAdmin {
  background: #2877EE;
  width: 30px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 15px;

  svg {
    path {
      fill: #fff;
    }
  }
}

//.sp__option--is-selected {
//  background-color: #FF5F00 !important;
//}

.addCardContainer {
  .addCard {
    display: flex;
  }
}

.addToCardWarning {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 26px;
      font-weight: 600;
      line-height: 39px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;

    .btn {
      width: 100px;
      box-shadow: unset !important;
      filter: unset;
    }
  }
}

.datepicker {
  .error {
    font-size: 12px;
    font-weight: 500;
    color: #EB001B;
  }

}

.react-datepicker {
  border-radius: 10px !important;
  border: none !important;
  box-shadow: $shadow;

  .react-datepicker__day--selected {
    //background-color: $primary-color;
    border: none;
    outline: none;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;

  }

  .react-datepicker__header {
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  }

}

.outOfStockBtn {
  height: 51px;
  width: 132px;
  border-radius: 5px;
  border: 1px solid #B8C1D2;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #6D7990;
  padding: 15px 26px;
  cursor: not-allowed;
}

.renewals {
  width: 100%;

  .renewalsContainer {
    display: flex;
    justify-content: space-between;

    .renewalsBlock {
      padding-left: 25px;

      .titleBlock {
        width: 100%;
        display: flex;

        .countProduct {
          margin-left: 15px;
          margin-top: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.59);
        }
      }

      .renewalsDescription {
        margin-bottom: 25px;

        > p {
          width: 470px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }

      .totalPrice {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #000000;

        > span {
          margin-left: 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
        }
      }
    }

    .addButton {
      display: flex;

      margin-top: 10%;

      .addProducts {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 242px;
        height: 60px;
        background: #0B8B00;
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        flex: none;
        order: 1;
        flex-grow: 0;

        .plus {
          width: 20px;
          height: 20.41px;

          > path {
            fill: #fff;
          }
        }
      }
    }
  }

  .itemBlock {
    margin-top: 17px;
    margin-left: 25px;
    height: 175px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    display: flex;
    align-items: center;

    > div {
      width: 0;

      &:nth-child(1) {
        width: 22%;
        padding-left: 45px;

        > img {
          width: 103px;
          height: 101px;
          object-fit: contain;
          border-radius: 10px;
        }
      }

      &:nth-child(2) {
        width: 43%;
        margin-top: -15px;

        > div {
          .itemTitle {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.35);
          }

          .itemPrice {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
          }
        }

        > h4 {
          margin-bottom: 5px;
        }
      }

      &:nth-child(3) {
        width: 25%;
        display: flex;
        justify-content: center;
      }

      &:nth-child(4) {
        width: 10%;

        > div {
          width: 53px;
          height: 53px;
          background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
          box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          > svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    .totalItem {
      width: 100%;
      justify-content: space-between;
      display: flex;
    }
  }

  .buttonsBlock {
    width: 100%;
    display: flex;
    margin-left: 25px;
    margin-top: 45px;
    margin-bottom: 60px;

    .save {
      width: 150px;
      height: 60px;
      background: #2877EE;
      box-shadow: 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      margin-right: 25px;
    }
  }
}

.myProfileContainer {
  width: 66%;
}

.resetPasswordForm {
  width: 387px;
  margin-bottom: 30px;

  .input_wrapper {
    margin: 10px 0 20px 0;

    .error {
      top: 0;
    }
  }

  .input-container {
    > span {
      margin-bottom: 10px;
    }
  }

  .input-secret {
    padding: 0 52px;
  }

  .input-icon {
    top: 19px;
  }
.input-icon-right{
  cursor: pointer;
}
  .btn {
    height: 50px;
    width: 250px;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
  }

  .close {
    path {
      fill: #bbc0cd;
    }
  }
}
