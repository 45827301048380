
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  color: #15274B;
  font-family: 'Poppins';
  background: #E9EDF0;

  &.logout {
    background-image: url("../images/drug.png");
  }
}

$shadow: 10px 10px 20px 0 #A6B4C8B2, -12px -12px 20px 0 #FFFFFFCC;

.container {
  max-width: 1200px;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.orange {
  color: #FF5F00;
}

.red {
  color: #EB001B;
}

.green {
  color: #0B8B00;
}

.purple {
  color: #5D5FEF;
}

.pink {
  color: #EF5DA8;
}

.sign-up-first,
.sign-up-second,
.sign-up-third,
.forgot-password,
.new-password,
.product-information-container, {
  display: flex;
  justify-content: center;

  .btn {
    min-height: 60px;
  }
}

.products-filter,
.orderHistory,
.reset-password,
.user-account,
.pantry-boxes,
.free-scale,
.express-order,
.favourites,
.my-transactions,
.my-profile,
.personal-health-profile,
.logOutNav,
.footer-layout,
{
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
}

ul li {
  list-style: none;
}

input {
  outline: none;
}

.error {
  color: #F40000 !important;
  position: relative;
  top: -16px;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.message-style {
  margin: 6px 0 2px 0 !important;

  label {
    padding: 0 !important;

    img {
      width: 100%;
      height: 99px
    }
  ;
  }
}

.input {
  font-size: 14px;
  font-weight: 600;
}

.font-small {
  font-size: 14px;
}

.btn {
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px 10px 20px rgba(166, 180, 200, 0.7);
  font-size: 13px;
  border: none;
  cursor: pointer;

  &.btn-big {
    font-size: 16px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-teal {
  color: #FFFFFF;
  background: #2877EE;
}

.btn-teal-big {
  line-height: 50px;
  padding: 5px 0;
  font-size: 20px;
}

.btn-teal-small {
  padding: 15px 0;
  font-size: 13px;
}

.btn-white {
  color: #15274B;
  padding: 5px 0;
  background: linear-gradient(176.28deg, #EEF0F5 76.11%, rgba(198, 206, 218, 0) 230.21%);
  filter: drop-shadow(10px 10px 20px rgba(166, 180, 200, 0.7));
}

.btn-white-small {
  padding: 12px 0;
}

.add-to-card-container {
  height: 51px;
  width: 149px;
}

.add-to-card {
  font-size: 13px;
  padding: 15px 16px;
  font-family: 'Poppins-SemiBold';
}

.add-to-card svg {
  margin-right: 10px;
}

.icon-teal-container {
  width: 50px;
}

.icon-red-container {
  width: 43px;
}

.icon-button-heart {
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 10px;
  box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
  position: relative;

  .count {
    position: absolute;
    top: -4px;
    right: -5px;
    background: #2877EE;
    width: 18px;
    height: 18px;
    border-radius: 40px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
  }
}

.icon-button-heart-small {
  padding: 12px 11px;
}

.circle {
  border-radius: 50px;
  position: absolute;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: inset -12px -12px 20px rgba(255, 255, 255, 0.6);
  top: -20px;
}

.checked-circle {
  width: 45px;
  height: 45px;
  border: 4px solid #0B8B00;
}

.number-circle {
  width: 49px;
  height: 49px;
}

.circle img {
  left: 11px;
  position: absolute;
  top: 12px;
}

.number-circle img {
  left: 19px;
  top: 18px;
}

.line {
  width: 95%;
  //width: 338px;
  height: 9px;
  background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
  box-shadow: inset 10px 10px 20px #FFFFFF;
  border-radius: 10px;
  margin-left: 15px;
}

.step-one {
  left: -2px;
}

.step-two {
  left: 43%;
}

.step-three {
  right: 0;
}

.need-help {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.teal {
  border-radius: 10px;
}

.red path {
  fill: #EB001B;
}

.sign-up {
  height: 936px;
}

.sign-up-form {
  margin: 0 auto;
}

.sign-up-form, .login-form {
  width: 590px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: -12px -12px 20px rgba(166, 180, 200, 0.7);
  border-radius: 20px;
}

.input_wrapper {
  position: relative;

  label {
    &:before,
    &:after {
      display: block;
      position: absolute;
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      color: #15274B;
      padding: 5px;
      bottom: 29px;
      top: 16px;
    }

    &[data-append] {
      + input {
        padding-right: 30px;
      }

      &:after {
        content: attr(data-append);
        right: 0;
      }
    }

    &[data-prepend] {
      + input {
        padding-left: 30px;
      }

      &:before {
        content: attr(data-prepend);
        left: 5px;
      }
    }
  }

  input {
    color: #15274B;
    font-family: inherit;
  }
}

.input-icon {
  position: absolute;
  top: 30px;
  width: 15px;
  height: 15px;

  svg {
    width: 14px;
    height: 14px;
  }
}

.input-icon-right {
  right: 23px;
}

.input-icon-left {
  left: 25px;
}

.fileInput {
  position: relative;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #15274B;
    margin-bottom: 10px;
  }

  .file {
    height: 99px;
    width: 111px;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: 10px 10px 20px 0px #A6B4C8B2, -12px -12px 20px 0px #FFFFFFCC;
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    display: inline-block;
    cursor: pointer;
    padding: 24px 35px;

    input {
      visibility: hidden;
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}

//Reset buttons
.top-btn-block {
  border-radius: 10px;
  display: flex;
  margin: 52px 0 45px 0;

  .btn {
    box-shadow: -12px -12px 20px 0px #FFFFFFCC;

  }
}

.top-btn-block .btn {
  font-size: 14px;
  font-weight: 600;
}

.btn-account {
  width: 107px;
}

.btn-reset {
  width: 160px;
  margin-left: 20px;

}

//page-links
.page-links {
  width: 245px;
  height: 287px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
  border-radius: 10px;
  padding: 28px 27px;
}

.page-links-elements {
  border-bottom: 1px solid rgba(28, 59, 106, 0.1);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.page-links-elements:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.page-links-elements p {
  margin-top: 5px;
}

.page-links-elements {
  span, p {
    font-size: 14px;
  }


}

.page-links-elements p a {
  font-weight: 600;
  color: #1C3B6A;
  text-decoration: none;

  &.active {
    color: #FF5F00;
  }
}

.page-links-elements span {
  font-weight: 400;
  color: rgba(28, 59, 106, 0.54);
}

#wrapper-content {
  display: flex;
  justify-content: space-between;
  margin: 48px 0 15px 0;
}

.free-scale-products, .pantry-boxes-products {
  justify-content: space-between;
  width: 75%;
  display: flex;
  flex-wrap: wrap;
}

.free-scale, .pantry-boxes {
  .productItem {
    .tablets {
      display: none;
    }

    .bottom .price-title-tablets {
      margin-bottom: 20px;
    }

    //
    //.bottom {
    //  margin-top: 28px;
    //}
  }
}

//product slider
.product-slider-container {
  .slick-dots {
    width: 100%;
    margin-top: -5px;
    position: static;

    li {
      button {
        width: 14px;
        height: 14px;
        border-radius: 40px;
        box-shadow: $shadow;
        margin-right: 14px;
        background: linear-gradient(134.17deg, #FAFAFA 4.98%, #E6E9EF 94.88%);
        border: 0.5px solid;
        border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);

        &:before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background: #2877EE;
        }
      }

    }
  }

  .productItem {
    margin-bottom: 30px;
  }

  //.slick-initialized .slick-slide {
  //  > div {
  //    display: flex;
  //    width: 100%;
  //  }

  .slick-slider .slick-next,
  .slick-slider .slick-prev {
    display: none !important;
  }

}

.slick-slider {
  cursor: pointer;
}

//Select
.select-container {
  .sp__control {
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    border: none;
    height: 60px;
  }

  .sp__single-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #15274B;
  }

  .sp__menu {
    background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    background: #FFFFFF;
    color: #15274B;
    font-size: 14px;
    font-weight: 600;
  }

  .sp__control img {
    padding-right: 24px;
  }

  .sp__placeholder {
    color: #15274B;
    font-size: 14px;
    font-weight: 600

  }
}

.input-container {
  width: 340px;
}

.input {
  width: 100%;
  height: 60px;
  padding: 0 14px;
  background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
  box-shadow: inset -12px -12px 20px rgba(255, 255, 255, 0.8), inset 10px 10px 20px rgba(166, 180, 200, 0.7);
  border-radius: 10px;
  border: none;
}

.input::placeholder {
  color: #15274B;
  font-size: 14px;
  font-weight: 600;
}

.input-block span {
  font-weight: 600;
}

.social-icon {
  width: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 10px;
  margin-left: 20px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
}

.social-icon-container {
  width: 50px;
}

.sign-up-step {
  position: relative;
}

//Productitem
.productItem {

  .bottom {
    margin-top: 14px;

    .without-title {
      display: none;
    }
  }

  .top {
    display: flex;
    flex-direction: column;

    .itemInfo {

      .top {
        display: flex;
        flex-direction: column;

        .itemInfo {

          .fsa {
            font-size: 13px;
            font-weight: 600;
            color: #FF8338;
          }

          .item-fsa {
            display: flex;
            width: 58%;
            justify-content: space-between;
          }
        }
      }

      .icon-button-heart {
        box-shadow: none;
      }
    }
  }
}

//logoutNav
.logOutNav {
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
  border-radius: 10px;
  height: 73px;

  .logout-nav-container,
  .logout-title {
    display: flex;
  }

  .logout-nav-container {
    justify-content: space-between;
  }

  .title {
    border-right: 0.75px solid #00758B;
    margin: 0 24px 0 12px;
    padding-right: 18px;
  }

  .help {
    font-size: 14px;
    margin-right: 12px;
    text-align: center;
  }
}

//Sign-up-first Page
.sign-up-first {
  padding-top: 115px;

  .sign-up-first-form {
    height: 600px;
    //height: 616px;
    padding: 41px 102px 55px 102px;
  }

  .sign-up-first-form p {
    font-size: 18px;
    line-height: 38px;
  }

  .btn-teal-big {
    margin: 15px 0 10px 0;
  }

  input {
    color: #15274B;
    font-size: 14px;
    font-weight: 600;
    margin-top: 11px;
  }

  .input-container {
    //width: 100%;
    width: 386px;
  }

  .button-container .btn {
    line-height: 50px;
  }

  .sign-up-step-container {
    margin-bottom: 65px;
  }

  .line {
    margin-top: 23px;
  }

  .button-container {
    margin-bottom: 34px;
  }

  .button-container .btn {
    font-weight: 600;
  }

  .step {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 331px;
    top: 44px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: inset -12px -12px 20px rgba(255, 255, 255, 0.6);
    border-radius: 50px;

    &.third-step {
      left: 580px;
    }
  }

  .step img {
    position: absolute;
    top: 17px;
    left: 20px;
  }

  .login-link-block {
    display: none;
  }
}

//Sign-up-second
.sign-up-second {
  height: 980px;
  padding-top: 62px;

  .sign-up-second-form {
    //width: 590px;
    height: 890px;
    //transform: translate(51%, 7%);
    padding: 35px 102px;
  }

  .input-container input {
    width: 386px;
  }

  .input {
    margin: 8px 0 15px 0;
  }

  .question-input .input {
    margin-top: 15px;
  }

  .input-select {
    display: flex;
    width: 386px;
    justify-content: space-between;
  }

  .third-step {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 552px;
    top: 23px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: inset -12px -12px 20px rgba(255, 255, 255, 0.6);
    border-radius: 50px;
  }

  .third-step img {
    position: absolute;
    top: 17px;
    left: 20px;
  }

  .line {
    margin-top: 16px;
  }

  .form-input-container {
    display: flex;
    flex-wrap: wrap;
  }

  .form-input-container .input-block,
  .form-input-container .question-block {
    width: 386px;
  }

  .form-input-container .question-block input::placeholder {
    color: #15274B;
    opacity: 0.7;
  }

  .form-input-container .input-block {
    margin-right: 21px;
  }

  .sign-up-step-container {
    margin-bottom: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .question-block {
    margin-top: 7px;
  }

  .birthday-block span {
    font-weight: 600;
  }

  .btn-submit {
    width: 386px;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 25px;
  }

  .input-select .sp__control {
    background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
    box-shadow: inset -12px -12px 20px rgba(255, 255, 255, 0.8), inset 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    border: none;
    height: 60px;
    margin: 8px 0 11px 0;
  }

  .date .sp__control {
    width: 78px;
  }

  .month .sp__control {
    width: 174px;
  }

  .year .sp__control {
    width: 113px;
  }

  .input-select .sp__placeholder {
    display: flex;
    justify-content: center;
  }


  .input-select .sp__single-value {
    display: flex;
    justify-content: space-around;
  }

}

//Sign-up-third Page

.sign-up-third {
  padding-top: 112px;

  .sign-up-third-form {
    //width: 590px;
    height: 634px;
    padding: 47px 0 43px 102px;
  }

  .step-three {
    right: 116px;
  }

  h2 {
    margin: 70px 0 22px 0;
  }

  .input-container {
    width: 386px;
  }

  .input-secret {
    padding: 10px 52px 0 52px;;
  }

  .input-container input {
    margin: 8px 0 15px 0;
  }

  .line {
    width: 76%;
  }

  .step-two {
    left: 35%;
  }

  .step-three {
    right: 20%;
  }

  .wrong-password {
    display: flex;
    align-items: center;
  }

  .wrong-password span {
    font-size: 12px;
    color: #EB001B;
    font-weight: 500;
    margin-left: 8px;
  }

  .btn-save {
    width: 386px;
    font-weight: 600;
    margin: 15px 0 35px 0;
    font-size: 16px;
  }
}

.log-in {
  height: 812px;

  .input-secret {
    padding: 10px 52px 0 52px;;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    height: 655px;
    padding: 37px 102px 43px 102px;
    margin: 80px auto 0 auto;
  }

  h2 {
    padding-bottom: 20px;
  }

  .title-block span {
    font-size: 20px;
    font-weight: 600;
  }

  .title-block {
    padding-bottom: 28px;
  }

  .password-text {
    display: flex;
    justify-content: space-between;

    a {
      font-weight: 600;
      text-decoration: none;
      color: #15274B;
    }
  }

  .input-container {
    width: 386px;
  }

  .input {
    margin: 6px 0 24px 0;
    color: #15274B;
  }

  .input-checkbox-block {
    display: flex;
    align-items: center;
    margin-top: -7px;

  }

  .input-block label {
    color: #2877EE;
    font-size: 16px;
    font-weight: 600;
  }

  .input-checkbox {
    width: 24px;
    height: 24px;
    margin-right: 9px;
    background: #00758B;
    box-shadow: 0 4px 4px rgba(166, 180, 200, 0.7);
    border-radius: 3px;
  }

  .btn-log-in {
    font-weight: 600;
    margin: 31px 0 33px 0;
    min-height: 60px;
  }

  .sign-up-text {
    font-weight: 700;
    color: #2877EE;
  }

  .input-checkbox-block span {
    display: none;
  }

  .sign-up-text-block {
    text-align: center;
    margin-bottom: 17px;
  }

  .sign-up-text {
    margin-left: 8px;
  }

  .frequent-questions {
    line-height: 50px;
    color: #2877EE;
    text-align: center;
    text-decoration: underline;
  }
}

//Product-information Page
.product-information-container {
  height: 465px;
  background: #E5E5E5;

  .product-information-element-block {
    margin: 0 auto;
  }

  .product-information-element-block {
    display: flex;
    justify-content: space-between;

  }

  .product-information-element {
    width: 31.5%;
    height: 333px;
    padding: 37px 58px 52px 58px;
    margin-top: 73px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #00758B;
    margin: 19px 0 3px 0;
  }

  .product-information-icon {
    width: 84px;
    height: 84px;
    border-radius: 50px;
  }

  article {
    line-height: 30px;
  }
}

//Personal-health-profile
.personal-health-profile {

  .personal-health-profile-text {
    padding: 11px 0 40px 0;
    width: 843px;
    font-size: 18px;
    font-weight: 400;
  }


  .globalSearch {
    margin-bottom: 32px;
  }

  .personal-help-profile-container span {
    display: none;
  }

  .personal-health-profile-element-container {
    display: flex;
    flex-wrap: wrap;
  }

  .btn-close {
    display: none;
  }

  .btn-question {
    display: none;
  }

  .personal-help-profile-container svg {
    display: none;
  }

  .personal-health-profile-element {
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    width: 285px;
    height: 77px;
    font-size: 18px;
    margin: 0 7px 20px;

    &.orange-element {
      background: #FF8338;
      color: #FFFFFF;
    }
  }

  .personal-health-profile-element .checkbox {
    width: 100%;
    height: 100%;
    padding: 8px 10px 8px 12px;

  }

  .checkbox label {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .checkbox {
    input:checked + label:after {
      border-color: #FF8338;
    }

    input:checked + label:before {
      background: #FFFFFF;
      box-shadow: unset;
    }

  }

  .button-block {
    //height: 74px;
    display: flex;
    margin: 32px 0 58px 0;
  }

  .button-block .btn-teal-big {
    font-weight: 600;
    width: 412px;
    margin-right: 20px;
    padding: 12px 0
  }

  .button-block .btn-white {
    width: 167px;
    font-size: 20px;
    font-weight: 600;
  }


}

//My Profile
.my-profile {
  .globalSearch {
    margin-bottom: 47px;
  }

  .select-block .select-container {
    display: flex;
    justify-content: space-between;
  }

  .select-container {
    margin-top: 6px;
  }

  .sp__placeholder {
    font-weight: 600;
  }


  .sp__single-value {
    font-size: 14px;
    line-height: 30px;
    font-weight: 600;
  }

  .first-select .sp__single-value {
    color: #FFFFFF;
  }

  .sp__menu {
    top: 52px;
  }

  .css-1pahdxj-control:hover {
    background: red;
  }

  .first-select .sp__control {
    background: #00758B;
  }

  .sp__placeholder {
    font-size: 18px;
  }

  .first-select .sp__placeholder {
    color: #FFFFFF;
  }

  .sp__option:hover {
    color: #FFFFFF;
    background: #FF5F00;
  }

  .personal-details-container,
  .email-address-container {
    display: flex;
    justify-content: space-between;
  }

  .personal-details-element, .email-address-element {
    width: 284px;
  }

  .personal-details-element label {
    font-weight: 600;
    font-size: 16px;

  }

  .state, .zip-code {
    width: 183px;

    .select-container {
      width: 100%;
    }
  }

  .state {
    margin-right: 20px;
  }

  .state-zip-code {
    display: flex;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #15274B;
  }

  .personal-details-element .input,
  .email-address-element .input,
  .state-zip-code .input {
    margin: 6px 0 20px 0;
    color: #15274B;
  }

  .personal-details-element span,
  .email-address-element span,
  .zip-code span,
  .state span {
    font-weight: 600;
    color: #15274B;
  }

  .top-btn-block {
    margin: 0 0 45px 0;
  }

  .btn-block {
    margin-bottom: 60px;

    .btn-teal {
      height: 70px;
      width: 388px;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .title-btn-block {
    display: flex;

  }


  .personal-details-email-address-container {
    display: flex;
    justify-content: space-between;
  }

  .personal-details-container,
  .email-address-container {
    display: flex;
    flex-direction: column;

  }

  .personal-details,
  .email-address {
    width: 386px;
  }

  .personal-details-element,
  .email-address-element {
    width: 100%;
  }

  &.email-address-element.city {
    width: 100%;
  }

  .page-links {
    width: 286px;
  }
}

//Favourites Page
.favourites {

  .globalSearch {
    margin-bottom: 38px;
  }

  h2 {
    margin-bottom: 32px;
  }

  .favourite-product-items {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination {
    margin-bottom: 82px;
  }
}

//My-transactions
.my-transactions {
  padding-bottom: 25px;

  .noTransactions {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #15274B;
  }

  .globalSearch {
    margin-bottom: 76px;
  }

  h2 {
    font-weight: 600;
  }

  .input-container {
    width: 273px;
  }

  .input-container span {
    color: #1C3B6A;
  }

  .input-button-container {
    display: flex;
    margin: 20px 0 28px 0;
  }

  .input-block {
    display: flex;
    margin-right: 20px;
  }

  .input-amount {
    margin-right: 20px;
  }

  .input {
    margin-top: 7px;
  }

  .input::placeholder {
    color: #15274B;
    opacity: 0.5;
    font-size: 16px;
  }

  .button-block {
    display: flex;
    margin-top: 31px;
    width: 100%;
    justify-content: space-between;
  }

  .button-block .btn {
    height: 60px;
    font-size: 14px;
    font-weight: 600;
  }

  .btn-teal-big {
    width: 200px;
  }

  .btn-download {
    width: 150px;
    background: #0B8B00;
    color: #FFFFFF;
  }

  .btn-download svg {
    margin-right: 12px;
  }

  .transaction-table-title-element p,
  .transactions-content-element p,
  .transactions-content-element span {
    font-size: 14px;
    font-weight: 600;
  }

  .transaction-table-title-element p {
    color: #2877EE;
  }

  .btn-see-more {
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #1C3B6A;
    display: flex;

  }

  .btn-see-more svg {
    margin: 2px;
  }

  .btn-see-more-container {
    display: flex;
    justify-content: flex-end;
    margin: 13px 0 33px 0;
  }

  .column-style {
    display: flex;
    flex-direction: column;
  }

  .transaction-table-titles, .transactions-content {
    display: flex;
    background: linear-gradient(134.17deg, #EEF0F5 60.7%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    height: 58px;
    padding: 20px 40px 20px 37px;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .transactions-content {
    height: 100px;
  }

  .one, {
    width: 13%;
  }

  .two {
    width: 14%;
  }

  .three {
    width: 40%;
  }

  .four {
    width: 23%;
  }

  .six img {
    margin: 3px 5px 0 0;
  }

  .seven {
    width: 4%;
  }

  .six, .five {
    width: 10%;
  }

  .six {
    display: flex;
  }
}

//Express Order Page
.express-order {
  #wallets {
    margin: 23px -11px 0 -11px;;
  }

  .globalSearch {
    margin-bottom: 25px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #2877EE;
  }

  .title-button-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .addIconBlock {
      width: 50px;
      height: 50px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .express-order-titles {
    display: flex;
    background: linear-gradient(134.17deg, #EEF0F5 60.7%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    height: 56px;
    padding: 20px 25px 20px 25px;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .order-item {
    width: 15%;
  }

  .product {
    width: 15%;
  }

  .wallet {
    width: 15%;
  }

  .unit-price {
    width: 15%;
  }

  .quantity {
    width: 15%;
  }

  .total-price {
    width: 25%;
  }

  .disabledStock {
    position: absolute;
    z-index: 999999;
    color: red;
    font-size: 18px;
    transform: rotate(343deg);
  }

  .express-order-content {
    display: flex;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    height: 86px;
    padding: 20px 25px 20px 25px;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 600;

    &.with-opacity input::placeholder {
      opacity: 0.3;
    }
  }

  .express-order-table-title-element,
  .express-order-content-element {
    font-size: 14px;

    label {
      &:before,
      &:after {
        display: block;
        position: absolute;
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        color: #15274B;
        padding: 5px;
        bottom: 29px;
        top: 6px;
      }

      &[data-append] {
        + input {
          padding-right: 30px;
        }

        &:after {
          content: attr(data-append);
          right: 0;
        }
      }

      &[data-prepend] {
        + input {
          padding-left: 30px;
        }

        &:before {
          content: attr(data-prepend);
          left: 5px;
        }
      }
    }
  }

  .express-order-table-title-element {
    color: #2877EE;
    font-weight: 600;
  }

  .express-order-content-element .input {
    height: 53px;
    //margin-top: -5px;
    width: 122px;
  }

  .input::placeholder {
    color: #1C3B6A;
  }

  .express-order-content-element .deleteBlock {
    cursor: pointer;
    width: 53px;
    height: 53px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgb(255 255 255 / 80%), 10px 10px 20px rgb(166 180 200 / 70%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    > svg {
      width: 22px;
      height: 22px;
    }
  }

  .express-order-content {
    display: flex;
    align-items: center;
  }

  .express-order-content .total-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .express-order-block {
    margin-bottom: 40px;
  }

  .paymentAndButton {
    width: 100%;

    .paymentDetails {
      width: 289px;
      height: 173px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      margin-top: 30px;

      > h3 {
        width: 117px;
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        padding-top: 25px;
        margin-left: 20px;
        color: #000000;
      }

      .totalPrice {
        width: 100%;
        display: flex;
        margin-left: 20px;

        .totalName {
          width: 65%;
          height: 21px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          margin-bottom: 10px;

          > svg {
            margin-right: 5px;
          }
        }

        .totalPrice {
          width: 45px;
          height: 21px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
        }
      }
    }

    .buttonBlock {
      width: 100%;
      display: flex;
      margin-top: 40px;

      .proceedCheckOut {
        width: 290px;
        height: 75px;
        background: #2877EE;
        box-shadow: 10px 10px 20px rgba(166, 180, 200, 0.7);
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-right: 10px;
        color: #FFFFFF;
      }

      .cancel {
        width: 290px;
        height: 75px;
        background: linear-gradient(176.28deg, #EEF0F5 76.11%, rgba(198, 206, 218, 0) 230.21%);
        filter: drop-shadow(10px 10px 20px rgba(166, 180, 200, 0.7));
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 50px;
        margin-right: 27px;
        color: #2877EE;
      }

      .continueShopping {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #0B8B00;
      }
    }
  }

  //.btn-teal {
  //  display: none;
  //}
}


//Free scale Page
.free-scale {

  //.content-block{
  //  display: flex;
  //  justify-content: space-between;
  //  margin: 48px 0 15px 0;
  //}
  //.free-scale-products{
  //  justify-content: space-between;
  //  width: 75%;
  //  display: flex;
  //  flex-wrap: wrap;
  //}
  //.page-links{
  //  width: 245px;
  //  height: 365px;
  //  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  //  box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
  //  border-radius: 10px;
  //  padding: 28px 27px;
  //}
  //.page-links-elements{
  //  border-bottom: 1px solid rgba(28, 59, 106, 0.1);
  //  margin-bottom:10px;
  //  padding-bottom: 10px;
  //}
  //.page-links-elements:last-child{
  //  margin-bottom:0;
  //  border-bottom:none;
  //}
  //.page-links-elements p{
  //  margin-top: 5px;
  //}
  //.page-links-elements span, p{
  //  font-size: 14px;
  //}
  //.page-links-elements p{
  //  font-weight: 600;
  //}
  //.page-links-elements span{
  //  font-weight: 400;
  //  color: rgba(28, 59, 106, 0.54);
  //}
  .tablets {
    margin: 23px -11px 0 -11px;
  }

  .content-block {
    display: flex;
    justify-content: space-between;
    margin: 48px 0 15px 0;
  }

  .free-scale-products {
    justify-content: space-between;
    width: 75%;
    display: flex;
    flex-wrap: wrap;

    .productItem .top .icon path {
      fill: #2877EE;
    }
  }


  .page-links {
    width: 245px;
    height: 287px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    padding: 28px 27px;
  }

  .page-links-elements {
    border-bottom: 1px solid rgba(28, 59, 106, 0.1);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .page-links-elements:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  .page-links-elements p {
    margin-top: 5px;
  }

  .page-links-elements span, p {
    font-size: 14px;
  }

  .page-links-elements p {
    font-weight: 600;
  }

  .page-links-elements span {
    font-weight: 400;
    color: rgba(28, 59, 106, 0.54);
  }

  .productItem .title {
    margin: 4px 0 0 0;
  }

  .pagination {
    margin-bottom: 30px;
  }
}


//User account Page
.user-account {

  .user-account-container {
    width: 100%;
    margin-bottom: 250px;
  }

  h2 {
    color: #15274B;
    margin-bottom: 14px;
  }

  .title-btn-block .btn {
    width: 204px;
    height: 55px;
    background: #0B8B00;
    font-size: 14px;
    font-weight: 600;
  }

  .title-btn-block .btn svg {
    margin-right: 10px;
  }

  .title-btn-block {
    display: flex;
    justify-content: space-between;
  }

  .profile-block p {
    margin-bottom: 10px;
  }

  .profile {
    width: 111px;
    height: 111px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
  }

  .btn-block .btn-big-teal {
    width: 335px;
    height: 70px;
    font-size: 20px;
    font-weight: 600;
  }

  .select-container .sp__indicators {
    display: none;
  }

  .profile-block p,
  .input,
  .personal-details-element label {
    font-weight: 600;
    color: #15274B;
    font-size: 16px;

  }

  .user-account-field-container {
    width: 100%;
    display: flex;
    margin-top: 22px;
  }

  .element-triplets,
  .element-triplets-select {
    width: 386px;
  }

  .btn-block {
    margin-top: 20px;
  }

  .input, .select-container {
    margin: 5px 0 15px 0;
  }

  .element-triplets-select {
    margin-left: 100px;
  }

  .top-btn-block {
    margin: 70px 0 37px 0;
  }
}

//Reset-password Page
.reset-password {
  h2 {
    color: #00758B;
    margin-bottom: 20px;
  }

  .reset-password-container {
    margin-bottom: 170px;
  }

  .input,
  .reset-password-element label {
    font-weight: 600;
    color: #00758B;
    font-size: 16px;

  }

  .reset-password-element {
    width: 386px;
  }

  .btn-block .btn-big-teal {
    width: 335px;
    height: 70px;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
  }

  .input {
    margin: 5px 0 15px 0;
  }

  .forgot-password-link {
    padding-top: 10px;
  }

  .forgot-password-link a {
    color: #15274B;
    font-weight: 500;
  }
}

//Home Page
.home {
  .custom-select-menu-container {
    width: 1200px;
    height: 458px;
    padding: 44px 0 44px 40px;
    background: #E9EDF0;
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    margin-top: 20px;
    position: absolute;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
    //backdrop: static!important;
    .custom-select-menu {
      column-count: 3;
      column-rule: 1px solid #d7d5d5;
      column-gap: 60px;
    }

    .subtitle-container {
      font-weight: 400 !important;
      margin-top: 10px;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      margin-top: 10px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .custom-select-menu-container::-webkit-scrollbar {
    display: none;
  }

  .pagination {
    margin: 25px 0 37px 0;
  }

  .product-item {
    height: 200px;
    background: #aac3e8;
  }

  .categories-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
    position: relative;

    .select-container {
      .sp__control {
        background: #FF8338;
        width: 203px;
        color: #FFFFFF;

        .sp__single-value {
          color: #FFFFFF;
          font-size: 16px;
        }

        .sp__placeholder {
          color: #FFFFFF;
          font-size: 16px;
          font-weight: 600;
        }

        svg path {
          fill: #FFFFFF;
        }
      }
    }
  }
}

//addToCard Page
.addToCard {
  .shoppingCartContainer {
    flex-direction: column;
  }

  .shopping-cart-summary {
    margin-bottom: 76px;
    display: flex;
    justify-content: space-between;
  }

  .back svg path {
    fill: #2877EE;
  }

  .qtyPicker {
    input {
      width: 49px;
      height: 35px;
    }
  }
}

//Order history
.orderHistory {
  .order-history-content {
    width: 78%;

    .empty {
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
      color: #15274B;
      margin-bottom: 11px;
      width: 100%;
      text-align: center;
      margin-top: 25px;
    }
  }

  .data-picker-container {
    display: flex;
    height: 58px;
    width: 334px;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    align-items: center;
    padding: 16px 0 12px 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    cursor: pointer;

    svg {
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }
  }

  .data-picker-container span {
    margin: 0 14px 0 9px;
  }

  .pagination {
    margin: 50px 0 51px 0;
  }

  .tablet-number, .tablet-price {
    display: none;
  }
}

//Single Product
.singleProduct {
  .product-slider-container {
    margin-bottom: 40px;
  }

  .productItem {
    margin-top: 30px;
    margin-left: 10px;
  }

}

//payment methods
.paymentMethods {
  .card-type-btn-block {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 242px;
      height: 60px;
      padding: 20px 24px;
      font-size: 14px;
      font-weight: 600;
      background: #0B8B00;
      margin-top: -95px;
    }

    .btn svg {
      margin-right: 10px;
    }
  }

  .cards {
    margin-bottom: 63px;

    .bankName img {
      height: 38px;
      width: 44px;
      margin-bottom: 12px;
    }

    .actions {
      margin-top: -10px;
    }
  }
}

//Filter Products Page
.products-filter {

  .filter-products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 44px 0 60px 0;

    .filter-product-item {
      width: 285px;
      height: 122px;
      display: flex;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      padding: 13px 19px 13px 19px;
      margin-bottom: 20px;

      img, p {
        width: 50%;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .chat-container {
    width: 405px;
    height: 536px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    position: absolute;
    top: 442px;
    right: 88px;

    .chat-header {
      height: 71px;
      display: flex;
      padding: 15px 18px 15px 38px;
      border-radius: 10px;
      background: #5D5FEF;
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);

      .close {
        margin-left: 98px;
      }

      .rectangle {
        margin-right: 17px;
      }

      svg path {
        fill: #FFFFFF;
      }

      .title-text {
        color: #FFFFFF;

        p {
          font-weight: 600;
          font-size: 14px;
        }

        span {
          font-weight: 400;
          font-size: 11px;
        }
      }
    }

    .chat-content-container {
      font-size: 14px;
      font-weight: 400;
      padding: 20px;
      height: 376px;
      overflow: scroll;


      .date {
        text-align: center;
      }

      .chat-element-container {
        margin-bottom: 5px;

        span {
          margin-left: 10px;
        }

        &.me {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-end;

          .chat-element {
            background: #5D5FEF;
            color: #FFFFFF;
          }
        }

        .chat-element {
          width: 261px;
          padding: 13px 22px;
          background: linear-gradient(134.17deg, #EEF0F5 60.7%, #E6E9EF 94.88%);
          box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
          border-radius: 50px;
        }
      }
    }

    .chat-footer {
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);


      .input {
        width: 306px;
        height: 53px;
        padding: 0 30px;
        background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
        box-shadow: inset -5px -5px 15px rgba(255, 255, 255, 0.75), inset 5px 5px 10px rgba(166, 180, 200, 0.75);
        border-radius: 50px;
      }

      .input::placeholder {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
      }

      svg {
        margin-left: 17px;
      }
    }
  }
}

//Chat Page
.chat {
  &.desktop {
    position: fixed;
    right: 10px;
    top: calc(100vh - 586px);
  }

  .noMessages {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: #15274B;
  }

  .chat-container {
    width: 375px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    transition: width 300ms ease-in-out, transform 300ms 300ms ease-in-out;

    &.closed {
      transition: width 300ms 300ms ease-in-out, transform 300ms ease-in-out;
      width: 220px;

      .chat-header {
        padding: 24px 16px 20px 22px;
        cursor: pointer;

        .rectangle {
          margin-right: 13px;
        }

        .title-text span, .close {
          transition: opacity 0ms 0ms ease-in-out;
          opacity: 0;
        }
      }

      .chat-content-container {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        transition: all 300ms ease-in-out;
      }

      .chat-footer {
        height: 0;
        overflow: hidden;
        transition: height 300ms 0ms ease-in-out;
      }

    }

    .chat-header {
      height: 71px;
      display: flex;
      padding: 15px 18px 15px 38px;
      background: #5D5FEF;
      justify-content: space-between;
      border-radius: 10px;

      > div {
        display: flex;
      }

      .rectangle {
        margin-right: 17px;
      }

      .close {
        padding: 0 5px;

        svg {
          cursor: pointer;
        }
      }

      svg path {
        fill: #FFFFFF;
      }

      .title-text {
        color: #FFFFFF;

        p {
          font-weight: 600;
          font-size: 14px;
        }

        span {
          font-weight: 400;
          font-size: 11px;
          transition: opacity 300ms 300ms ease-in-out;
        }
      }
    }

    .chat-content-container {
      font-size: 14px;
      font-weight: 400;
      padding: 13px 16px;
      height: 415px;
      overflow: scroll;
      transition: all 300ms 300ms ease-in-out;

      .date {
        text-align: center;

        p {
          margin-top: 10px;

        }
      }

      .chat-element-container {
        margin-bottom: 10px;

        span {
          margin-left: 10px;
          z-index: 5;

        }

        &.me {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-end;

          .chat-element {
            background: #5D5FEF;
            color: #FFFFFF;
          }
        }

        .chat-element {
          width: 261px;
          padding: 13px 22px;
          background: linear-gradient(134.17deg, #EEF0F5 60.7%, #E6E9EF 94.88%);
          box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
          border-radius: 50px;
        }
      }
    }

    .chat-footer {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      position: relative;
      overflow: hidden;
      transition: height 300ms 300ms ease-in-out;

      .typing {
        position: absolute;
        left: 13px;
        bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #272744;
        font-style: italic;
      }

      button {
        background: transparent;
        border: none;
        outline: none;
      }

      .input {
        width: 306px;
        height: 53px;
        padding: 0 30px;
        background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
        box-shadow: inset -5px -5px 15px rgba(255, 255, 255, 0.75), inset 5px 5px 10px rgba(166, 180, 200, 0.75);
        border-radius: 50px;
      }

      .input::placeholder {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
      }

      svg {
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }
}

//catalog Page
.catalog {
  .category-title, .brand-title {
    font-weight: 600;
    margin: 10px 0;
  }

  .category {
    margin-bottom: 32px;

    .category-elements {
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          svg {
            display: block;

            path {
              fill: #2877EE;
            }
          }
        }

        .hovered {
          color: #2877EE;
        }
      }

      svg {
        cursor: pointer;
        margin-left: 10px;
        display: none;

        path {
          fill: #15274B;
        }
      }

      p {
        line-height: 30px;
      }
    }
  }

  .productItem {
    margin-bottom: 23px;
  }

  .brand {
    margin-bottom: 47px;

    .input_wrapper {
      margin-bottom: 10px;

      .input-icon {
        top: 13px;
      }

      .input-icon-right {
        right: 11px;
      }
    }

    .brand-elements {
      max-height: 186px;
      overflow-x: hidden;
    }

    input {
      margin: 0 0 20px 0;
      height: 42px;
      padding-left: 34px;
    }

    .brand-elements .checkbox {
      margin-bottom: 13px;

      label {
        font-weight: 400;
      }
    }


    .brand-search {
      position: relative;
      left: 10px;
      top: 38px;
      z-index: 1
    }

  }

  .filter-item-container {
    overflow-x: hidden;
    margin-left: -17px;
    margin-right: 0;
    padding-top: 15px;
  }

  .filterItem {
    margin-right: 12px;
    margin-left: 12px;
  }

  .pagination {
    margin: 30px 0 45px 0;
  }

  .catalog .categoriesContainer .products {
    height: 1288px;
  }

  .filterItem .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    color: #1C3B6A;
  }

  .filterItem .catalogSelect {
    margin-bottom: 15px;
    //display: none;
    .checkbox {
      label {
        font-weight: 500;
      }
    }

    &.selected {
      background: #FF8338;
      color: white;

      svg {
        path {
          fill: white;
        }
      }

      .checkbox {
        input:checked + label:before {
          background: white;
        }

        input:checked + label:after {
          border-color: #FF8338;
        }
      }
    }
  }

  .filterItem .checkbox label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .arrowIcon {
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      transition: .2s;
    }

    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

//Forgot-password  Page
.forgot-password {
  margin-bottom: 35px;

  .button-block {
    width: 386px;
  }


  .forgot-password-form {
    display: flex;
    flex-direction: column;
    width: 588px;
    padding: 66px 101px 84px 101px;
    margin: 100px 0 0 0;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgb(166 180 200 / 70%);
    border-radius: 20px;

    .input-secret {
      padding: 10px 52px 0 52px;
    }

    .icon {
      cursor: pointer;
    }

    .select-container {
      margin-bottom: 20px;
    }

    .input-container {
      width: 386px;

      span {
        color: #15274B;
      }

      .input {
        margin: 8px 0 20px 0;
      }
    }

    .button-block {
      .btn-teal {
        margin: 23px 0 10px 0;
      }

      .btn {
        height: 60px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

//New password Page
.new-password {
  height: 942px;

  .new-password-form {
    height: 391px;
    width: 588px;
    padding: 58px;
    margin: 208px 0 0 0;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgb(166 180 200 / 70%);
    border-radius: 20px;

    .text-block {
      text-align: center;
      margin-bottom: 40px;

      p {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;

        span {
          font-weight: 700;

        }
      }

      .opacity-text {
        font-size: 16px;
        font-weight: 500;
        opacity: 0.5;
      }
    }

    .button-block {
      .btn-white {
        margin-top: 10px;
      }

      .btn {
        height: 60px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

//footer
.footer-layout {
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
  border-radius: 10px;

  .footer-element-container {
    display: flex;
    justify-content: space-between;
    margin: 56px 0 30px 0;
  }

  .social-icon-block {
    display: flex;
  }

  .footer-element ul li {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;

    &.menu-title {
      font-weight: 700;
    }
  }

  .questions {
    font-weight: 600 !important;
  }

  .footer-element ul li a {
    color: #15274B;
    text-decoration: none;
  }

  .logo {
    width: 15%;
  }

  .logo p {
    color: #15274B;
    font-size: 32px;
    font-weight: 400;
  }

  .big {
    width: 45%;
    display: flex;
  }

  .menu-other {
    width: 40%;
    display: flex;
  }

  .middle {
    width: 58%;
  }

  .small {
    width: 42%;
  }

  .footer-icons {
    width: 30%;
  }

  .big ul {
    width: 70%;
  }

  .footer-element__text-size-small ul {
    margin-right: 105px;
  }

  .footer-copyright-element {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .footer-copyright-element p {
    font-weight: 300;
    font-size: 12px;
  }

}

#navBar {
  .menu {
    align-items: center;
    padding: 0 10px;

    li a:after {
      content: "";
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: -1px;
      left: 0;
      border-radius: 10px 10px 0 0;
      transition: all 0.4s;
      top: 42px;
    }

    li a.active {
      color: #2877EE;
    }

    li a:hover:after {
      background: #2877EE;
    }

    li a.active:after {
      background: #2877EE;
    }
  }

  li {
    position: relative;
    cursor: pointer;
  }

  .buttonsBlock .buttons {
    font-weight: 500;
    font-size: 12px;
    color: #15274B;
  }

  .button-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }

  .button-element p {
    margin-top: 1px;
  }

  .tablet-rectangle,
  .tablet-search {
    display: none;
  }

  //li:hover:after {
  //  background: #cccccc;
  //}

  //li.active:after {
  //  background: #2877EE;
  //}
  .responsive-menu {
    display: none;
  }
}

.sp__control {
  padding-right: 8px;
}

.singleProductSliderContainer {
  display: flex;
  width: 61%;

  .slick-list {
    height: 295px !important;
  }

  .singleProductSlider::-webkit-scrollbar {
    display: none;
  }

  .singleProductSlider {
    width: 183px;
    padding: 30px 16px;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    height: 354px;
    overflow-y: auto;

    .sliderItem {
      .border {
        margin: 15px 0;
        box-shadow: 0 4px 4px 0 #00000040 inset;
        height: 0.25px;
        background: rgba(0, 0, 0, .25);
      }
    }

    img {
      width: 140px;
      height: 100px;
      object-fit: contain;
    }

    .slick-track {

      img {
        width: 140px;
        height: 100px;
        object-fit: contain;
      }
    }
  }

  .mainImageContainer {
    height: 354px;
    width: 436px;
    border-radius: 10px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    border: 0.5px solid;
    border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
    box-shadow: $shadow;
    margin: 0 74px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .mainImage {
      height: auto;
      width: 365px;
      display: flex;
      max-height: 100%;
      max-width: 100%;

      img {
        height: auto;
        width: 365px;
        object-fit: contain;
      }
    }
  }
}

//Check Out
.contWrapper {
  width: 100%;
  display: flex;

  .leftBlock {
    width: 70%;

    .mainCheckOut {
      width: 100%;
      margin-bottom: 35px;


      .open_card_title {
        height: 27px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        text-transform: capitalize;
        color: #272744;
        padding-right: 15px;

        .current_option_text {
          height: 24px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #7C7C9E;
        }
      }

      .addNewBlock {
        width: 100%;
        display: flex;

        .newBlockLeftWrapper {
          padding-right: 10px;
          padding-top: 20px;

          .newBlockLeft {
            width: 131px;
            height: 165px;
            cursor: pointer;
            background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
            border-radius: 10px;
            justify-content: center;
            display: flex;
            align-items: center;
            flex-direction: column;

            .newText {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              text-transform: uppercase;
              color: #272744;
            }

            .plus {
              font-size: 30px;
              width: 16px;
              height: 35px;
            }
          }
        }


        .addCart {
          height: 178px;
        }

        .newBlockCenterWrapper {
          display: flex;
          overflow-x: auto;
          padding-left: 36px;
          padding-bottom: 20px;
          padding-top: 20px;

          .newBlockCenter {
            min-width: 287px;
            height: 165px;
            margin-right: 18px;
            background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
            border-radius: 10px;
            cursor: pointer;
          }

          .newBlockCenterDefault {
            min-width: 287px;
            height: 165px;
            margin-right: 18px;
            background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
            border-radius: 10px;
            border: 1px solid #2877EE;
          }

          .titleBlock {
            display: flex;
            justify-content: space-between;
            padding: 20px;

            .titleBlockLeft {
              .titleBlockTitle {
                width: 111px;
                height: 24px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #272744;
                padding-right: 10px;
              }

              .title__text {
                width: 49px;
                height: 24px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #272744;

              }
            }
          }

          .bottomText {
            width: 245px;
            height: 72px;
            padding-left: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #272744;
          }
        }

        .cartWrapper {
          display: flex;
          overflow-x: auto;
          padding-left: 36px;
          padding-bottom: 20px;
          padding-top: 20px;

          .visaBlock {
            min-width: 158px;
            height: 165px;
            margin-right: 18px;
            background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
            border-radius: 10px;
            cursor: pointer;
          }

          .checkVisaBlock {
            min-width: 158px;
            height: 165px;
            margin-right: 18px;
            background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
            border-radius: 10px;
            border: 1px solid #2877EE;
          }
        }
      }

      .visaImage {
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
          width: 100px;
          height: 100px;
        }
      }

      .cartNumber {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
        height: 16.56px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #272744;
      }

      .expiers {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #272744;
      }

      .expired {
        height: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #F40000;
        padding-left: 35px;
      }
    }
  }

  .confirmPayBlock {
    width: calc(30% - 30px);
    margin-left: 30px;
    height: 295px;

    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;

    .confirmPayTop {
      width: 100%;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      display: flex;

      > div {
        padding: 35px 5px 0;

        &:last-child {
          > div {
            text-align: right;
          }
        }

        > div {
          padding-bottom: 35px;
          height: 24px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #15274B;
        }

        .totalText {
          width: 55px;
          height: 33px;
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 33px;
          color: #15274B;
        }

        .total {
          left: 76.15%;
          right: 19.64%;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #15274B;
        }
      }
    }

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 91%;
      height: 58px;
      background: #2877EE;
      border-radius: 10px;
      outline: none;
      cursor: pointer;
      border: none;
      margin-top: 30px;
      margin-left: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 50px;
      color: #FFFFFF;
    }
  }
}


.addNewAddressModal {
  background: #FFFFFF;
  padding: 21px 21px 50px 15px;
  width: 634px;
  height: 795px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  border-radius: 20px;

  .top {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .deleteIcon {
      width: 12px;
      height: 12px;
      cursor: pointer;

      path {
        fill: #15274B;
      }
    }
  }

  .content {
    padding-right: 29px;
  }

  .title {
    width: 177px;
    height: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 auto;
    color: #15274B;
  }

  .countrySelect {
    width: 400px;
    margin: 15px auto;

    > p {
      color: #F40000 !important;
      position: relative;
      top: -16px;
      font-weight: 400 !important;
      font-size: 14px !important;
    }

    .spSelect {
      margin-bottom: 10px;

      .sp__control {
        height: 60px;
        background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
        box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
        border-radius: 10px;

        &.sp__control--menu-is-open {
          .selectArrow {
            transform: rotate(180deg);
          }
        }
      }

      .sp__menu {
        border-radius: 10px;
      }

      .sp__single-value {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #15274B;
      }

      .sp__menu-list {
        border-radius: 10px;
        border: 1px solid #B8C1D2;
        background: #E9EDF0;
        padding: 17px 25px;

        .sp__option {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #15274B;
          cursor: pointer;
        }

        .sp__option--is-selected {
          font-weight: 600;
          background: transparent;
        }

        .sp__option--is-focused {
          background: transparent;
          font-weight: 600;

        }
      }
    }

    > span {
      width: 66px;
      height: 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #15274B;
    }
  }

  .addAddressBut {
    margin: 0 auto;
    padding: 10px;
    width: 400px;
    height: 58px;
    background: #2877EE;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 50px;
    color: #FFFFFF;
    flex: none;
  }
}

.infoProductTable {
  width: 100%;
  margin-bottom: 55px;

  .infoTitleBlock {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    .infoTitle {
      display: flex;
      width: 100%;
      height: 56px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;

      > div {
        display: flex;
        justify-content: center;
        width: 150px;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #2877EE;
      }
    }
  }

  .infoTableBlock {
    width: 100%;
    display: flex;

    .infoTableList {
      margin-bottom: 15px;
      width: 100%;
      height: 78px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      display: flex;

      > div {
        width: 160px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #15274B;

        &:nth-child(1) {
          padding-left: 10px;
        }

        &:nth-child(2) {
          width: 110px;
        }


        &:nth-child(3) {
          width: 138px;
        }

        &:nth-child(5) {
          width: 114px;
        }
      }

      .deleteBlock {
        cursor: pointer;
        width: 53px;
        height: 53px;
        background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
        box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
        border-radius: 10px;
        margin-top: 13px;
        margin-left: 65px;

        > svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

}


.catalogSelect {
  height: 76px;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  border: 0.5px solid;
  border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
  box-shadow: $shadow;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 17px;

  &.filterSelect {
    height: 60px;

    svg path {
      fill: #15274B;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

}

.filterItem {
  //background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  border: 0.5px solid;
  border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
  border-radius: 10px;
  margin-bottom: 10px;

  &.open {
    margin-bottom: 27px;
    padding-bottom: 18px;
    //box-shadow: $shadow;

  }

  .item {
    padding: 20px 35px 0 15px;

    .checkbox input:checked + label:after {
      top: 3px;
    }

    span {
      width: 173px;
      height: 1px;
      box-shadow: 0 4px 4px 0 #00000040 inset;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      color: #1C3B6A;
    }

    .checkbox {
      margin-bottom: 13px;

      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.genderSelect {
  width: 100%;
  margin: 10px 0;
}

.catalogSelectFilter {
  .spSelect {
    max-width: 200px;

    .sp__value-container, .sp__single-value {
      color: #1C3B6A;
    }

    .sp__control {
      height: 76px;
      width: 100%;
      border-radius: 10px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      border: 0.5px solid;
      border-image-source: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%);
      box-shadow: $shadow;
      cursor: pointer;
      padding: 15px 17px;
      color: #1C3B6A;
    }

    .sp__option {
      font-size: 14px;
    }
  }

  .sp__option--is-selected {
    background: #FF8338;
  }

  &.filterSelect {
    height: 60px;

    svg path {
      fill: #15274B;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

}


