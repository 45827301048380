@media screen and (max-width: 1024px) {
  .container {
    max-width: 984px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .checked-circle {
    width: 30px;
    height: 30px;
    border: 2px solid #0B8B00;
  }
  .circle {
    top: -11px;
  }
  .circle img {
    left: 8px;
    position: absolute;
    top: 8px;
    width: 12px;
  }
  .number-circle {
    width: 35px;
    height: 35px;
  }
  //Wallet
  .walletType {
    width: 315px;
    padding: 16px 10px;

    .items {
      white-space: nowrap;
    }
  }

  .free-scale,
  .pantry-boxes {
    //.productItem .bottom {
    //  margin-top: 50px;
    //}

    .productItem {
      width: 313px;
    }

    .productItem .addToCardContainer .btn {
      height: 61px;
      width: 160px;
    }

    .pagination {
      margin: 17px 0 42px 0;
    }
  }
  ///Sign-up-first Page
  .sign-up-first {
    .button-container {
      display: flex;
      align-items: center;
    }

    .btn-white {
      margin-left: 20px;
    }

    .button-container .btn {
      height: 75px;
    }

    .sign-up-step-container {
      margin-bottom: 55px;
    }

    .sign-up-first-form {
      height: 510px;
      padding: 28px 85px 37px 85px
    }

    .step-three img {
      width: 10px;
      left: 12px;
      top: 9px;
    }

    .step-two img {
      width: 10px;
      left: 12px;
      top: 9px;
    }

    .input-container {
      width: 100%;
    }
  }

  ///Sign-up-second Page
  .sign-up-second {
    .sign-up-second-form {
      padding: 32px 74px 51px 74px;
      height: 688px;
      width: 950px;
    }

    .question-block {
      margin-top: 32px;
    }

    .question-input .input_wrapper {
      margin-top: 32px;
    }

    .btn-submit {
      margin: 10px 0 52px 0;
    }

    .line {
      width: 44%;
    }

    .step-two {
      left: 21%;
    }

    .step-three {
      right: 53%;
    }

    .step-three img {
      width: 10px;
      left: 12px;
      top: 9px;
    }
  }
  //Sign-up-three Page
  .sign-up-third {
    .sign-up-third-form {
      width: 760px;
      padding: 47px 0 38px 91px;
    }

    .line {
      width: 55%;
    }

    .step-two {
      left: 25%;
    }

    .step-three {
      right: 43%;
    }

    .sign-up-third-form {
      height: 605px;
    }
  }
  //Free scale Page
  .free-scale {
    //.productItem {
    //  height: 456px;
    //  width: 313px;
    //}
    //
    //.productItem .addToCardContainer .btn {
    //  height: 61px;
    //  width: 160px;
    //}
    //
    ////.productItem .title {
    ////  margin: 26px 0 4px 0;
    ////}

    .free-scale-products {
      width: 66%;
    }

    //.pagination {
    //  margin: 17px 0 42px 0;
    //}
  }
  //Pantry boxes page
  .pantry-boxes-products {
    justify-content: space-around;
    width: 67%;
  }

  //footer
  .footer-layout {
    .logo {
      width: 19%;
    }

    .big {
      width: 34%;
      display: flex;
      flex-direction: column;
    }

    .big ul {
      width: 100%;
      margin-bottom: 15px;
    }

    .menu-other {
      width: 47%;
    }

    .footer-copyright-element p {
      margin-top: -50px;
    }

    .footer-copyright-element {
      margin-bottom: 0;
    }

    .footer-element ul li {
      margin-bottom: 20px;

      &.sortpak-pharmacy {
        margin-bottom: 10px
      }

      &.Glendale-ave {
        margin-bottom: 15px;
      }

      &.questions, &.number {
        margin-bottom: 5px;
      }

      &.all-day {
        margin-bottom: 0;
      }
    }

    .footer-copyright-element {
      justify-content: initial;
    }

    .footer-icons {
      margin-left: 150px;
    }
  }
  .product-information-container {
    .product-information-element {
      padding: 29px 24px 40px 24px;
      line-height: 24px;
    }

    article {
      line-height: 24px;
    }
  }
  //Personal health profile Page
  .personal-health-profile {
    .personal-health-profile-element {
      width: 315px;
    }

    .button-block {
      margin: 22px 0 47px 0;
    }

  }

  //My profile Page

  .my-profile {
    .email-address-container {
      display: flex;
      flex-direction: column;
    }

    .state-zip-code {
      flex-direction: column;
    }

    .zip-code {
      margin-left: -337px;
    }

    .personal-details-container,
    .email-address-container {
      flex-direction: row;
    }

    .state, .zip-code {
      width: 100%;
    }

    .personal-details-email-address-container {
      display: flex;
      flex-direction: column;
      justify-content: unset;
    }

    .personal-details-element,
    .email-address-element {
      width: 97%;
    }

    &.email-address-element.city {
      width: 100%;
    }

    .personal-details,
    .email-address {
      width: 100%;
      //width: 47.7%;
    }

    .element-couple {
      width: 48%;
      margin-right: 12px;
    }

    .menuSelects .sp__control {
      width: 203px;
    }

    .menuSelects .health-profile .sp__control {
      width: 315px;
    }

    .personal-details h3, .email-address h3 {
      margin-bottom: 15px;
    }

    .personal-details-element .input,
    .email-address-element .input {
      margin: 6px 0 15px 0;
    }

    .city .input {
      width: 100%;
    }

    .btn-teal-big {
      width: 37%;
    }

    .page-links {
      width: 245px;
    }

    .personal-details-email-address {
      width: 68%;
    }
  }

  //Favourites Page
  .favourites {
    .productItem {
      width: 31.7%;
    }
  }

  //My transactions Page
  .my-transactions {
    .transaction-word {
      display: none;
    }

    .one, {
      width: 11%;
    }

    .two {
      width: 14%;
    }

    .three {
      width: 27%;
    }

    .four {
      width: 20%;
    }

    .five, .six {
      width: 12%;
    }

    .six {
      display: flex;
    }

    .seven {
      width: 4%;
    }

    .btn-download {
      width: auto;
      color: #0b8b00;
      background: transparent;
      box-shadow: none;
    }

    .btn-download svg path {
      fill: #0b8b00;
    }

    .btn-see-more {
      display: flex;
      margin-bottom: 50px;
    }

    .transaction-table {
      margin-bottom: 20px;
    }

    .btn-see-more-conatiner {
      display: flex;
      justify-content: flex-end;
    }

    .btn-see-more-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  //User account Page
  .user-account {
    .title-btn-block .btn {
    }

    .profile-block {
      display: none;
    }

    .user-account-field-container {
      justify-content: space-between;
    }

    .element-triplets-select {
      margin-left: 0;
    }

    .element-triplets-select {
      display: flex;
      flex-direction: column;
    }

    .state {
      order: 3;
    }

    .city {
      order: 2;
    }

    .btn-block {
      margin-top: 5px;
    }
  }

  //Home Page
  .home {
    .productItem {
      width: 313px;
    }

    .pagination {
      margin: 5px 0 55px 0;
    }

  }

  //addToCard
  .addToCard {
    .shoppingCartContainer p:last-child {
      display: block;
    }

    .shoppingCart {
      width: 100%;
    }

    .shopping-cart-summary {
      flex-direction: column;
    }

    .orderSummary {
      width: 100%;
      height: 159px;
      display: flex;
      justify-content: space-between;
    }

    .item {
      display: flex;
      flex-direction: column;
    }

    .order-title-items {
      width: 60%;
      display: flex;
      flex-direction: column;
    }

    .item-block {
      display: flex;
    }

    .order-title-items {
      width: 45%;
    }

    .title {
      margin-bottom: 5px;
    }

    .item-block {
      display: flex;
      justify-content: space-between;
    }

    .action {
      width: 35%;
    }

    .infoTable .head {
      .details {
        width: 37%;
      }

      .qty {
        width: 22%;
      }

      .total, .price {
        width: 13%;
      }
    }

    .infoTable .body {
      .details {
        width: 44%;
      }

      .qty {
        width: 20%;
      }

      .price, .total {
        width: 12%;
      }

      .actions {
        width: 2%;
      }
    }
  }

  //Order history Page
  .orderHistory {
    .data-picker-container {
      padding: 12px 18px 12px 19px;
      width: 325px;

      span {
        margin: 0 8px 0 7px;
      }
    }

    .order-history-content {
      width: 71.5%;
    }

    .infoTable .title .tablet-number, .tablet-price {
      display: flex;
    }

    .infoTable .title .tablet-number {
      margin-bottom: 8px;
    }

    .infoTable .title .tablet-price {
      margin-top: 8px;
    }

    .download-receipt {
      display: none !important;
    }

    .price,
    .number,
    {
      display: none;
    }

    .infoTable .body .row {
      height: 150px;
    }

    .infoTable .title {
      flex-direction: column;
    }

    .infoTable .row .img img {
      width: 97px;
      margin-right: 20px;
    }

    .infoTable .row .img {
      width: 17%;

    }

    .infoTable .row .img {
      width: 100%;

    }

    .infoTable .row .title {
      align-items: flex-start;
      //width: 33%;
    }

    .infoTable .tablet-dose {
      width: 42%;
    }

    .infoTable .row .tablets {
      width: 60%;
    }

    .infoTable .tablets {
      width: 17%;
    }

    .infoTable .row .title {
      width: 38%;
    }

    .infoTable .dose {
      width: 13%;
    }

    .infoTable .date {
      width: 20%;
    }

    .infoTable .dots {
      transform: rotate(90deg);
      width: 7%;
      margin-bottom: 72px;
    }

    .infoTable .date {
      width: 22%;
    }

    .data-picker-one svg {
      display: none;
    }

    .data-picker-two svg {
      position: relative;
      left: 180px;
      top: 2px;

    }

    .infoTable .row > div {
      justify-content: space-between;
    }

    .infoTable .title-tablets-dose-date-price {
      width: 75%;
    }

    .infoTable .mobile-number-image-block {
      width: 18%;
    }

    .title-tablets-dose-date-price {
      justify-content: space-between;
    }

    .data-picker-two .react-datepicker-wrapper {
      position: relative;
      right: 33px;
    }
  }

  //Single Page
  .singleProduct {
    .singleProductItem .mainImage {
      width: 368px;
      margin: 0 23px 0 15px;
    }

    .infoBlock {
      width: 40%;

      .icon-button-heart {
        box-shadow: none;
      }
    }

    .double-slider-container {
      width: 58%;

      .double-slider {
        .nav1, .nav2 {
          height: 366px;
        }

        .nav1 {
          width: 154px;
        }

        .nav2 {
          width: 368px;
        }
      }
    }

    .relatedProducts {
      margin-top: 43px;
    }

    .singleProductItem .imagesBlock {
      width: 154px;
      padding: 30px 20px;
    }

    .singleProductItem .imagesBlock span {
      width: 130px;
      margin-left: -9px;
    }

    .product-slider-container {

      .productItem {
        width: 315px;
        margin-left: 7px;
      }
    }
  }
  //Catalog Page
  .catalog {
    .productItem {
      width: 315px;
    }

    .product-slider-container {
      .productItem {
        margin-left: 7px;
      }
    }

    .categoriesContainer .products {
      width: 66.3%;
    }

    .categoriesContainer .filters {
      width: 29%;
    }

    .shopByCategories .catalogSelect {
      width: 126px;
      height: 56px;

      p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .filterByPrice {
      width: 380px;

      .range {
        height: 56px;

        input {
          height: 36px;
          width: 54px;
        }
      }
    }

    .pagination {
      margin: 31px 0;
    }

    .mobile-filter-container {
      width: 100%;
    }

    .categoriesContainer {

    }

    .filter-close {
      width: 100%;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      margin-bottom: 11px;

      p {
        margin-left: 10px;
      }

      .mobile-filter-container .filters {
        background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
        box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
        border-radius: 10px;
      }
    }

    .mobile-filter-container .btn {
      height: 50px;
      margin: 7px 0 16px 0;
      font-weight: 600;
      font-size: 14px;
    }

    .catalog .categoriesContainer .products {
      overflow: hidden;
      border-radius: 10px;
    }
  }


  //Payment Methods Page
  .paymentMethods {
    .cardTypes {
      margin-bottom: 41px;
    }

    .cards {
      margin-bottom: 233px;
    }
  }
  //Add Payment Methods Page
  .addPaymentMethod {
    .front {
      margin-bottom: 21px;
    }

    .container {
      align-items: normal;
      padding: 0;
    }

    .addPaymentMethodContainer {
      .h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0 30px 0;
      }
    }

    .actions {
      margin-bottom: 40px;
    }

    .termsBlock {
      margin: 32px 0 21px 0;
    }

    .addPaymentMethodWrapper {
      .bankName {
        margin-bottom: 0;
      }

      img {
        width: 50px;
        height: 43px;
      }

      .cardInput {
        height: 37px;
        width: 258px;

        &.expiry {
          width: 120px;
        }

        &.cvc {
          width: 92px;
        }
      }

    }
  }


  //Filter Products Page
  .products-filter {
    .filter-products-container {

      .filter-product-item {
        width: 315px;
      }
    }

    .chat-container {
      top: 596px;
      right: 19px;
    }

  }

  //New Password Page
  .new-password {
    .new-password-form {
      padding: 45px 100px;
      margin: 247px 0 0 0;

      .text-block {
        text-align: start;
      }
    }
  }
  //Forgot Password Page
  .forgot-password {
    .forgot-password-form {
      margin: 247px 0 0 0;
    }
  }

  //navbar
  #navBar {
    .container {
      margin-bottom: 5px;
    }

    .button-element p,
    .menu {
      display: none;
    }

    .tablet-rectangle,
    .tablet-search {
      display: flex;
    }

    .buttonsBlock .icon path {
      fill: #2877EE;
    }

    .tablet-rectangle {
      order: 2;
    }

    .tablet-search {
      order: 1;
    }

    .tablet-express-order {
      order: 4;
    }

    .tablet-wishlist {
      order: 3;
    }

    .tablet-basket {
      order: 5;
    }

    .logo {
      height: 32px;
      margin: 5px auto;
    }

    .responsive-menu {
      width: 41px;
      height: 41px;
      display: flex;
      margin-right: 35px;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    }

    .logo-block {
      display: flex;
      margin-left: 5px;
    }

    .logo-block {
      width: 48px;
      height: 41px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
    }
  }

}

@media screen and (max-width: 768px) {
  .container {
    width: 95%
  }
  .sign-up {
    height: auto;
  }
  .pagination ul {
    margin-left: -6px;
  }
  .sign-up-form, .login-form {
    box-shadow: none;
    border-radius: 0;
  }

  .mobile-title {
    font-size: 18px;
    font-weight: 500;
  }
  .express-order-title {
    font-size: 18px;
    line-height: 30px;
  }
  .mobile-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.31);
    height: 42px;
    margin-right: -17px;
    margin-left: -17px;
  }

  .mobile-title-container .btn-left {
    margin-left: 15px;
  }
  .mobile-title-container .btn-right {
    margin-right: 15px;
  }

  .mobile-title-container button {
    background: transparent;
    border: none;
  }

  .mobile-footer-layout,
  .mobile-express-order {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //mobile express order
  .mobile-express-order-form {
    padding: 21px 19px 21px 16px;
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
    box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    margin-top: 23px;


    .product-name {
      margin-bottom: 14px;
    }

    .product-name p {
      margin-bottom: 6px;
    }

    .mobile-express-order-form-container .title p,
    .mobile-express-order-form-element p {
      font-size: 13px;
      color: #0C55C2;
      font-weight: 600;
    }

    .mobile-express-order-form-container .title span,
    .mobile-express-order-form-element span {
      font-size: 14px;
      font-weight: 600;
    }

    .product-item .input {
      width: 266px;
      height: 35px;
      margin-left: 12px;
    }

    .product-item .input::placeholder {
      color: #1C3B6A;
    }

    .product-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }

    .mobile-express-order-form-elements {
      display: flex;
    }

    .mobile-express-order-form-container .icon {
      position: absolute;
      right: 24px;
      margin-top: -11px;
    }

    .mobile-express-order-form-element {
      width: 25%;
      font-size: 13px;
    }
  }
  .btn-info-modal-close {
    position: absolute;
    top: 16px;
    right: 19px;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1) !important;

  }
  .modal-class {
    position: absolute;
    top: 132px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 10px;
    outline: none;
    padding: 46px 20px 23px 19px !important;
    box-shadow: 10px 10px 20px rgb(166 180 200 / 70%);
    background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
  }
  .btn-account {
    width: 52%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .btn-reset {
    width: 48%;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  //Express order Page
  .express-order {
    .btn-teal {
      display: flex;
      height: 60px;
      font-size: 14px;
      font-weight: 600;
      margin: 20px 0 35px 0;
    }

    .mobile-title-container {
      margin-bottom: 20px;
    }
  }

  //navBar
  #navBar {
    height: 42px;
    border-radius: 0;

    .title {
      font-size: 13px;
    }

    .button-element {
      margin-right: 8px;
    }

    .responsive-menu {
      margin-right: 13px;
    }

    .buttons .icon {
      height: 35px !important;
      width: 35px !important;
    }

    .user img {
      margin-right: 0;
      width: 34px;
      height: 34px;
      border-radius: 50%
    }

    .tablet-search,
    .tablet-wishlist,
    .user-name-settings,
    .dropdown {
      display: none;
    }

    .user {
      height: auto;
      box-shadow: none;
      padding: 0;
    }

    .logo-block, .responsive-menu {
      width: auto;
      height: auto;
      box-shadow: none;
    }

    .logo-block {
      margin-left: 6px
    }
  }
  //wallets slider
  .wallet-slider-container {
    //margin-top: 16px;
    margin-right: -12px;
    margin-left: -12px;

    .slick-slider .slick-next,
    .slick-slider .slick-prev {
      display: none !important;
    }

    .walletType {
      width: 96%;
      margin-left: 168px;
    }

    .slick-list {
      //overflow: visible;
    }
  }

  //Product Slider
  .product-slider-container {
    .bottom {
      .with-title {
        display: none;
      }

      .without-title {
        display: block;
      }
    }

    .productItem {
      width: 96%;
      margin-left: 58px;

      .top {
        .itemInfo {
          margin-top: 0;

          .fsa {
            display: none;
          }
        }
      }
    }

    .bottom .addToCardContainer {
      .qtyPicker {
        display: flex;
        width: 127px;
        justify-content: space-between;
        //margin-right: 24px;
        .symbol {
          font-size: 24px;
          font-weight: 600;
        }

        input {
          height: 35px !important;
          width: 70px !important;
        }
      }

      .btn {
        width: 41px;
        height: 41px;

        .icon {
          margin-right: 0;
        }

        .product-title {
          display: none;
        }
      }
    }
  }

  ///Sign-up-first Page
  .sign-up-first {
    padding-top: 0;

    .container {
      margin-left: 0;
      margin-right: 0;
    }

    .sign-up-first-form {
      width: 100%;
      padding: 18px 15px 0 15px;
    }

    .step-two {
      left: 45%;
    }

    .input-container {
      width: 100%;
    }

    .sign-up-first-form {
      padding: 46px 15px 176px 15px;
    }

    .need-help {
      display: none;
    }

    .btn.btn-big {
      font-size: 14px;
    }

    .login-link-block {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .login-link-block p {
      font-size: 16px;
      font-weight: 700;
      color: #2877EE;
    }

    .login-link-block span {
      margin-right: 3px;
    }

    .button-container {
      display: block;
    }

    .btn-white {
      margin-left: 0;
    }

    .sign-up-first-form {
      height: auto;
    }

  }
  .layoutLogOut {
    .mobile-title-container {
      margin-right: 0;;
      margin-left: 0;

      .btn-right {
        color: transparent;
      }
    }
  }
  //free-scale, pantry-boxes productItem
  .free-scale, .pantry-boxes {
    .productItem {
      .bottom {
        margin-top: 0;
      }
    }
  }
  //Sign-up-second
  .sign-up-second {
    padding-top: 0;

    .sign-up-form {
      height: auto;
    }

    .container {
      margin-left: 0;
      margin-right: 0;
    }

    .sign-up-second-form {
      width: 100%;
      padding: 18px 15px 0 15px;
    }

    .input-birthday-container,
    .sign-up-second .input-container,
    .form-input-container .input-block,
    .input-container,
    .input-container input,
    .input-select,
    .input-container .question-block {
      width: 100%;
    }


    .form-input-container {
      display: block;
    }

    .date .sp__control {
      width: 130px;
    }

    .month .sp__control {
      width: 300px;
    }

    .year .sp__control {
      width: 190px;
    }

    .btn-submit {
      font-size: 14px;
    }

    .form-input-container .question-block,
    .btn-submit {
      width: 100%;
    }


    .need-help {
      display: none;
    }

    .button-block {
      margin-bottom: -24px;
    }

    .line {
      width: 95%;
    }

    .step-two {
      left: 45%;
    }

    .step-three {
      right: 0;
    }

    .input-container input {
      margin: 5px 0 15px 0;
    }
  }
  //Sign-up-third
  .sign-up-third {
    padding-top: 0;

    .sign-up-third-form {
      width: 100%;
    }

    .sign-up-third-form {
      width: 100%;
      padding: 15px 15px 0 15px;
    }

    .line {
      width: 95%;
    }

    .step-two {
      left: 45%;
    }

    .step-three {
      right: 0;
    }

    .input-container {
      width: 100%;
    }

    .btn-save {
      width: 100%;
      font-size: 14px;
    }

    .need-help {
      display: none;
    }

    h2 {
      margin: 36px 0 14px 0
    }

    .input-container input {
      margin: 5px 0 15px 0;
    }

    .sign-up-third-form {
      width: 100%;
    }
  }
  //.login Page
  .log-in {
    height: auto;

    .login-form {
      width: 100%;
      padding: 42px 15px 0 15px;
      height: inherit;
      margin-top: 0;
    }

    .sign-up-text-block {
      margin-bottom: 118px;
    }

    .input, .input-container {
      width: 100%;
    }

    h2 {
      padding-bottom: 10px;
    }

    .title-block {
      padding-bottom: 23px;
    }

    .input {
      margin: 5px 0 10px 0;
    }

    .input-checkbox-block {
      justify-content: space-between;
    }

    .btn-log-in {
      margin: 17px 0 21px 0;
    }

    .sign-up-text-block span {
      line-height: 30px;
    }

    .password-text {
      display: none;
    }

    .input-checkbox-block span {
      display: block;
    }

    .frequent-questions {
      display: none;
    }
  }
  //Personal health profile Page
  .personal-health-profile {
    .personal-health-profile-text {
      display: none;
    }

    .personal-help-profile-container h2 {
      margin-bottom: 15px;
      font-size: 24px;
    }

    .personal-help-profile-container span {
      display: block;
    }

    .personal-help-profile-container svg {
      display: flex;
      margin-top: -16px;
    }

    .personal-help-profile-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }

    .personal-help-profile-container {
      margin-top: 41px;
    }

    .button-block {
      display: flex;
      flex-direction: column;
    }

    .button-block .btn-teal-big {
      padding: 5px 0;
      width: 100%;
    }

    .button-block .btn-white {
      width: 100%;
      height: 60px;
      margin-top: 10px;
    }

    .btn-question {
      border: none;
      display: flex;
    }

    .health-profile-info {
      //display: flex;
      width: 344px;
      height: 216px;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      padding: 46px 19px 23px 19px;
      top: 85px;
      position: absolute;
      z-index: 1;
      font-size: 14px;

    }

    .health-profile-info button {
      position: absolute;
      top: 16px;
      right: 16px;
      border: none;

    }

    .button-block .btn {
      font-size: 14px;
    }

    .button-block {
      margin: 25px 0 48px 0;
    }

    .search-input-container input {
      width: 276px;
      height: 33px;
      background: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%);
      box-shadow: inset -12px -12px 20px rgba(255, 255, 255, 0.8), inset 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 50px;
      border: none;
      padding: 0 20px;
    }

    .personal-health-profile-element {
      padding: 8px 6px;
      width: 48%;
      margin-bottom: 15px;
    }

    .personal-health-profile-element .checkbox label:before {
      padding: 8px;
    }

    .search-input-container input::placeholder {
      color: #1C3B6A;
      font-size: 14px;
    }

    .checkbox input:checked + label:after {
      top: 3px;
      left: 6px;
      width: 4px;
      height: 9px;
    }

    .checkbox label {
      font-size: 14px;
    }

  }
  //My profile Page
  .my-profile {
    .personal-details-email-address-container {
      flex-direction: column;
    }

    .personal-details-container,
    .email-address-container {
      flex-direction: column;
    }

    .email-address,
    .personal-details,
    .element-couple {
      width: 100%;
    }

    .zip-code {
      margin: 0;
    }

    .email-address .input,
    .personal-details .input {
      width: 100%;
    }

    .state-zip-code {
      flex-direction: row;
    }

    .state-zip-code .state,
    .state-zip-code .zip-code {
      width: 48%;
    }

    .btn-teal-big {
      width: 100%;
      padding: 5px 0;
    }

    .menuSelects .select-block .select-container {
      display: flex;
      flex-direction: column;
    }

    .menuSelects .sp__control,
    .menuSelects .health-profile .sp__control {
      width: 100%;
      margin-bottom: 10px;
    }

    .menuSelects {
      margin-bottom: 254px;
    }

    .mobile-title-container {
      margin-bottom: 20px;
    }

    .menuSelects .sp__control svg {
      display: none;
    }

    .personal-details-email-address {
      width: 100%;
    }
  }

  //favourites, free-scales productItem
  .favourites, .free-scale, .pantry-boxes, .home, .catalog {
    .productItem {
      width: 100%;

      .addToCardContainer {
        .qtyPicker input {
          height: 30px;
          width: 43px;
        }

        .btn {
          height: 37px;
          width: 115px;
          font-size: 10px;
        }
      }

    }
  }

  .favourites, .free-scale, .pantry-boxes, .home, .catalog, .singleProduct {
    .productItem {
      display: flex;
      height: 155px;
      padding: 9px 12px;
    }

    .bottom {
      margin-top: 0;

      .price-title-tablets {
        margin-bottom: 0;
      }
    }

    .top .imgContainer img {
      height: 104px;
    }

    .price-title-tablets {
      display: flex;
      flex-direction: column;
      margin-top: -20px;
    }

    .bottom {
      width: 65%;
    }

    .title {
      order: 1;
    }

    .tablets {
      order: 2;
    }

    .price {
      order: 3;
    }

    .mobile-icon-container {
      display: flex;
      align-self: flex-end;

      .icon svg path {
        fill: #2877EE;
      }
    }

    .mobile-icon-container .icon {
      margin: -5px -6px;
      width: 35px !important;
      height: 35px !important;
      box-shadow: none;
    }

    .title {
      font-weight: 600;
    }

    .top .itemInfo .item-fsa {
      flex-direction: column;
    }

    .itemInfo p {
      font-weight: 400;
    }

    .addToCardContainer .qtyPicker .symbol.minus {
      margin-right: 8px;
    }

    .addToCardContainer .qtyPicker .symbol.plus {
      margin-left: 8px;
    }

    .addToCardContainer .qtyPicker {
      margin-right: 4px;
      display: flex;
      align-items: center;
    }

    .addToCardContainer .icon {
      width: 16px;
    }

    .title {
      padding-top: 0;
    }

    .productItem .itemInfo p {
      font-size: 12px;
      padding-top: 7px;
    }

    .top {
      width: 35%;
    }

    .productItem .title {
      font-size: 14px;
    }

    .top .icon1 {
      width: 15px;
      height: 15px;
    }

    .itemInfo {
      margin-top: 25px;
      margin-bottom: 0;
    }

    .top .imgContainer img {
      width: 83px;
      margin-left: 0;
    }

    .top .imgContainer {
      margin: 0;
    }

    .bottom {
      display: flex;
      flex-direction: column;
    }

    .pagination ul {
      justify-content: center;
      //margin-left: -15px;
    }

    .pagination ul li {
      margin-left: 15px;
    }

    .pagination ul li:first-child {
      margin-left: 0;
    }

    .pagination ul li {
      width: 30px;
      height: 30px;
    }
  }

  //Favourites Page
  .favourites {
    //ProductItem
    .productItem {
      padding: 9px 9px 13px 8px;

      .mobile-icon-container .icon path {
        fill: #F40000;
      }
    }

    h2 {
      margin: 16px 0 20px 0;
    }

    .price {
      order: 3;
    }

    .addToCardContainer {
      margin-top: 5px;
      order: 4;
    }

    .qtyPicker input {
      height: 30px;
      width: 44px;
    }

    .mobile-title-container {
      margin-left: -17px;
      margin-right: -17px;
    }

  }


  //My transactions
  .my-transactions {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }

    .title-line {
      display: none;
    }

    .title-teal {
      color: #00758B;
    }

    .input-button-container {
      margin: 0;
    }

    .my-transactions-container .input-block {
      display: none;
    }

    .button-block .btn-teal-big {
      display: none;
    }

    .button-block {
      margin-top: -28px;
    }

    .my-transaction-card-element-container {
      width: 100%;
      height: 375px;
      padding: 24px 28px;
      background: linear-gradient(134.17deg, #EEF0F5 60.7%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      border-radius: 10px;
      font-size: 14px;
    }

    .my-transaction-card-element span {
      color: #00758B;
    }

    .my-transaction-card-element p {
      margin-top: 5px;
    }

    .my-transaction-card-element img {
      margin-right: 5px;
    }

    .status-block {
      display: flex;
    }

    .declined {
      display: flex;
    }

    .my-transaction-card-element {
      margin-bottom: 10px;
    }

    .my-transaction-card-element:last-child {
      margin-bottom: 0;
    }

    .btn-see-more {
      margin-bottom: 0;
    }

    .btn-see-more-container {
      display: flex;
      justify-content: flex-end;
      margin: 18px 0 20px 0;
    }

    .my-transaction-card-element-container {
      margin-bottom: 15px;
    }

    .button-block .btn {
      margin-top: 20px;
    }

    .two-elements {
      display: flex;
    }

    .two-elements .my-transaction-card-element {
      width: 50%;
    }

    .my-transactions-container {
      margin-top: 33px;
    }

    .btn-right {
      color: transparent
    }

    .btn-see-more svg {
      margin: 2px 0 0 4px;
    }
  }
  //Free scale Page
  .free-scale,
  .pantry-boxes,
  .my-profile,
  .orderHistory {
    #wrapper-content {
      flex-direction: column;
    }

    .page-links {
      width: 100%;
    }

    .free-scale-products {
      width: 100%;
    }

    .productItem {
      padding: 9px 9px 13px 8px;
    }

    .bottom {
      display: flex;
      flex-direction: column;
    }

    .top {
      width: 35%;
    }

    .productItem .title {
      font-size: 14px;
    }

    .top .icon1 {
      width: 15px;
      height: 15px;
    }

    .itemInfo {
      margin-bottom: 4px;
    }

    .top .imgContainer img {
      width: 83px;
      margin-left: 18px;
    }

    .top .imgContainer {
      margin: 0;
    }

    .addToCardContainer {
      margin-top: 14px;
    }

    .addToCardContainer .btn {
      height: 37px;
      width: 118px;
      font-size: 10px;
    }

    .qtyPicker input {
      height: 30px;
      width: 44px;
      //padding: 7px 0 7px 19px;
    }

    .icon-button-heart svg path {
      fill: #00758B;
    }

    .btn-teal-big {
      margin: 6px 0 42px 0;
    }

    .productItem .title {
      margin: 0 0 4px 0;
    }

    .productItem .price {
      margin-bottom: 0;
    }

    .page-links {
      margin-bottom: 38px;
    }
  }

  //Pantry-boxes
  .pantry-boxes {
    .pantry-boxes-products {
      width: 100%;
    }
  }
  //User account Page
  .user-account {
    .btn-right {
      color: transparent
    }

    .user-account-field-container {
      flex-direction: column;
    }

    .top-btn-block,
    .element-triplets,
    .element-triplets-select, {
      width: 100%;
    }

    .top-btn-block {
      margin: 40px 0 16px 0;
    }

    .title-btn-block {
      flex-direction: column;
    }

    .title-btn-block .btn {
      width: 100%;
      justify-content: flex-start;
      padding: 0 26px;
      margin-top: 22px;
    }

    .profile-mobile {
      background: #EBEDF3;
      border: 1px dashed #1C3B6A;
      border-radius: 5px;
      padding: 34px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .user-account-field-container {
      margin-top: 15px;
    }

    .btn-block {
      margin-top: 0;
    }

    h2 {
      margin-bottom: 0;
    }

    .user-account-container {
      margin-bottom: 36px;
    }

    .btn-block .btn-big-teal {
      width: 100%;
      height: 60px;
    }
  }

  //  //Reset password Page
  //  .forgot-password {
  //.forgot-password-form{
  //  border-radius: 0;
  //  width: 100%;
  //  margin: 0;
  //  .input-container{
  //    width: 100%;
  //  }
  //}
  //
  //  }

  //home Page
  .home {
    .container {
      align-items: normal;
      padding: 0;
    }

  }

  .singleProduct, .catalog {
    .product-slider-container {
      margin-bottom: 20px;
      margin-right: -12px;
      margin-left: -12px;


      .productItem {
        width: 285px;
        margin-left: 74px;
      }
    }
  }

  //Single Product
  .singleProduct {
    .container {
      padding: 0;
      align-items: normal;
    }

    .singleProductItem {
      flex-direction: column;
      height: auto;
      margin-top: -10px;
    }

    .singleProductItem .imagesBlock,
    .singleProductItem .mainImage {
      width: 100%;
    }

    .imagesBlock {
      flex-direction: row;
      order: 2;
    }

    .imagesBlock span {
      display: none;
    }

    .imagesBlock .mobile-drugs {
      display: flex;
    }

    .infoBlock {
      position: relative;
      order: 3;
      margin-top: 36px;
      width: 100%;
      overflow: hidden;
    }

    .singleProductItem .mainImage {
      margin: 0;
    }

    h2 {
      margin-top: 10px;
    }

    .infoBlock {
      .qtyPicker input {
        height: 45px;
        width: 75px;
      }
    }


    .dose {
      display: flex;
    }

    .dose .checkbox:nth-child(1) {
      margin-right: 55px;
    }

    .singleProductItem .icon.heart {
      width: 38px !important;
      height: 38px !important;
      position: absolute;
      right: -422px;
    }

    .mainImage img {
      width: 279px;
    }

    .descriptionContainer {
      padding: 21px 18px;

    }

    .descriptionContainer .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
    }

    .infoBlock .addToCardContainer .btn {
      height: 60px;
      width: 191px;
    }

    .double-slider-container {
      width: 100%;
      margin-top: 24px;

      .double-slider {
        flex-direction: column;

        .slide-border-div {
          border-right: 1px solid #2877EE;
          width: 50px;
          position: relative;
          left: -13px;
        }

        .nav1 {
          width: 100% !important;
          height: 73px !important;
          padding: 0;
          //border-left: 6px solid #2877EE;
          order: 1;

          .slick-current {
            background: #2877EE;
            border-radius: 10px;

            .nav1-element .slick-slide img {
              border-right: 1px solid #2877EE;
            }
          }

          .slick-slide img {
            margin-top: 0;
            //border-right: 1px solid #2877EE;
            height: 49px;
          }

          .nav1-element {
            display: flex !important;
            border-top: none;
            height: 73px !important;
            //padding: 14px 29px 10px 0;
            padding: 14px 14px 10px 11px;
          }

        }

        .nav2 {
          height: 253px;
          width: 100% !important;
          margin-bottom: 12px;
        }
      }
    }
  }

  //Add To cart
  .addToCard {
    .mobile-title-container {
    }

    .container {
      padding: 0;
      align-items: normal;
    }

    .order-title-items {
      width: 100%;
    }

    .orderSummary {
      height: 302px;
      padding: 25px 24px 25px 24px;
      flex-direction: column;
      justify-content: initial;
    }

    .orderSummary .btn {
      height: 50px;
    }

    .order-title-items p {
      color: #2877EE;
    }

    .item-block {
      flex-direction: column;
    }

    .action {
      width: 100%;
    }

    .item {
      flex-direction: row;
      margin-bottom: 20px;
    }
  }

  //Order history Page
  .orderHistory {
    .order-history-content {
      width: 100%;
    }

    .infoTable .body .row {
      padding: 0 16px;
    }

    .infoTable .row .img img {
      width: 64px;
      height: 65px;
    }

    .data-picker-container {
      width: 100%;
    }

    .data-picker-two svg {
      left: 580px;
    }

    .mobile-number-image-block {
      flex-direction: column;
    }

    .infoTable .number {
      display: block !important;
    }

    .infoTable .title .tablet-number {
      display: none;
    }

    .infoTable .number {
      width: 100%;
      margin-top: -67px
    }

    .infoTable .body .row {
      height: 204px;
    }

    .infoTable .row .img {
      margin-top: 12px;
    }

    .infoTable .row {
      .title-tablets-dose-date-price {
        flex-direction: column;
        margin-top: -7px;
      }
    }

    .infoTable .row .title {
      font-size: 16px;
      font-weight: 400;
    }

    .infoTable .row .title .tablet-price {
      display: none;
    }

    .infoTable .tablets .tablets-comma {
      display: flex;
    }

    .infoTable .dots {
      transform: none;
      margin-bottom: 140px;
    }

    .infoTable .dots svg {
      height: 14px;
    }

    .price,
    .tablets {
      display: flex;
    }

    .infoTable .mobile-number-image-block {
      width: 30%;
    }

    .infoTable .title-tablets-dose-date-price {
      width: 68%;
    }

    .infoTable .row .tablets, {
      width: 20%;
    }

    .infoTable .row .dots {
      width: 2%;
    }

    .infoTable .row .dose, {
      width: 45%;
    }

    .infoTable .row .title,
    .infoTable .row .date,
    .infoTable .row .tablet-dose,
    .infoTable .row .price {
      width: 100%;
    }

    .infoTable .row .date {
      margin: 6px 0 20px 0;
    }

    .infoTable .row .title {
      margin-bottom: 4px;
    }

    .infoTable .row .price {
      font-weight: 600;
      font-size: 20px;
    }
  }

  //Catalog Page
  .catalog {
    .container {
      padding: 0;
      align-items: normal;
    }

    .product-slider-container {
      margin-top: -18px;
    }

    .h2 {
      font-weight: 600;
      font-size: 20px;
      align-self: flex-start;
      display: flex;
    }

    .topProducts {
      .h2 {
        margin-top: -40px;
      }
    }

    .categoriesContainer {
      margin-top: -35px;
      display: block;

      .filters {
        width: 100%;
      }

      .products {
        width: 100%;

        .productItem {
          width: 100%;
        }
      }
    }

    .slick-list {
      padding: 20px 0;
    }

    .shopByCategories {
      .h2 {
        font-size: 20px;
        font-weight: 600;
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .filters-mobile {
      width: 100%;
      height: 57px;
      display: flex;
      border-radius: 10px;
      align-items: center;
      background: linear-gradient(134.17deg, #EEF0F5 46.99%, #E6E9EF 94.88%);
      box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
      justify-content: center;
      margin: 17px 0 10px 0;

      .count-text {
        display: flex;

        p {
          margin-left: -8px;
        }
      }

      .filter-count {
        width: 18px;
        height: 18px;
        background: #FF8338;
        box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);
        border-radius: 40px;
        color: #FFFFFF;
        position: relative;
        left: 54px;
        top: -7px;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .shopByCategories > div {
      align-items: initial;
      flex-direction: column;

      p {
        font-size: 18px;
        font-weight: 500;
        margin-top: -20px;
      }
    }

    .pagination {
      margin: 23px 0 20px 0;
    }

  }

  //Payment Methods
  .paymentMethods {
    .container {
      padding: 0;
      align-items: normal;
    }

    .addPaymentMethodContainer .h2 {
      font-size: 20px;
      margin: 0 0 25px 0;
    }

    .cards {
      margin-bottom: 46px;

      .addNewCard {
        display: none;
      }

      .card {
        margin-top: 32px;
        width: 100%;
        margin-right: 0;
        height: 217px;

        .cardNumber {
          display: flex;
          justify-content: center;
          margin-top: 25px;
        }

        .expiry {
          justify-content: center;
        }
      }
    }

    .card-type-btn-block {
      flex-direction: column;

      .btn {
        width: 100%;
        margin-top: 0;
        display: flex;
        justify-content: start;
      }
    }

    .cardTypes {
      margin-bottom: 10px;

      .cardType {
        margin-bottom: 20px;
      }
    }

    .mobile-title-container {
      .container {

      }
    }

  }

  //payment addPayment
  //Payment Methods Page
  .paymentMethods, .addPaymentMethod {
    .cardTypes .cardType {
      margin-bottom: 20px;
      width: 52.5px;
      margin-right: 17px;
    }

    .mobile-title-container {
      .btn-right {
        color: transparent;
      }
    }
  }
  .addPaymentMethod {
    .addPaymentMethodWrapper {
      width: 100%;
      height: 218px;
      padding: 10px 17px 18px 19px;
    }

    .addCard {
      flex-direction: column;
    }

    .actions {
      display: flex;
      flex-direction: column;
      margin-bottom: 55px;

      .btn {
        height: 50px;
        width: 100% !important;
      }

      .save {
        margin-bottom: 10px;
      }

      .front {
        margin-bottom: 21px;
      }

      .container {
        align-items: normal;
        padding: 0;
      }
    }
  }

  //Filter Products Page
  .products-filter {
    .filter-products-container {
      margin: 0 0 32px 0;

      .filter-product-item {
        width: 48%;
        margin-bottom: 14px;
      }
    }
  }

  //Reset password Page
  .forgot-password {
    display: flex;
    flex-direction: column;
    height: auto;

    .forgot-password-form {
      border-radius: 0;
      width: 100%;
      margin: 0 0 170px 0;
      padding: 105px 15px 0 15px;
      box-shadow: none;

      .input-container {
        width: 100%;

        .input {
          margin: 5px 0 11px 0;
        }
      }

      .button-block {
        width: 100%;

        .btn-teal {
          margin: 13px 0 10px 0;
        }
      }
    }

  }
  //Reset password Page
  .new-password {
    display: flex;
    flex-direction: column;
    height: auto;

    .new-password-form {
      border-radius: 0;
      width: 100%;
      margin: 0 0 170px 0;
      padding: 105px 15px 0 15px;
      box-shadow: none;

      .button-block {
        width: 100%;

        .btn-teal {
          margin: 13px 0 10px 0;
        }
      }
    }

  }
  //footer
  .footer-layout {
    border-radius: 0;

    .footer-element-container {
      margin: 27px 0 30px 0;
    }

    .logo {
      display: none;
    }

    .menu-other {
      width: 100%;
      margin-bottom: 15px;
    }

    .middle {
      width: 53%;
    }

    .small {
      width: 47%;
    }

    .big {
      width: 100%;
    }

    .big ul {
      margin-bottom: 10px;
    }

    .footer-icons {
      width: 100%;
      justify-content: center;
      display: flex;
      margin-left: 0;
    }

    .footer-copyright-element p {
      font-weight: 500;
      font-size: 10px;
      margin-top: 0;
      text-align: center;
      margin-bottom: 27px;
    }

    .footer-element-container {
      flex-direction: column;
    }

    .footer-copyright-element {
      justify-content: center;
      display: flex;
    }

  }

}

@media screen and (max-width: 700px) {
  .orderHistory {
    .infoTable .row .tablets, {
      width: 24%;
    }
  }
}

@media screen and (max-width: 660px) {
  .sign-up-second {
    .date .sp__control {
      width: 100px;
    }

    .month .sp__control {
      width: 250px;
    }

    .year .sp__control {
      width: 160px;
    }
  }
  //Order history Page
  .orderHistory {
    .data-picker-two svg {
      left: 480px;
    }
  }
}

@media screen and (max-width: 550px) {
  .sign-up-second {
    .date .sp__control {
      width: 80px;
    }

    .month .sp__control {
      width: 200px;
    }

    .year .sp__control {
      width: 110px;
    }
  }
  //Order history Page
  .orderHistory {
    .data-picker-two svg {
      left: 380px;
    }

    .infoTable .row .tablets, {
      width: 28%;
    }
  }
}

@media screen and (max-width: 430px) {
  .sign-up-second {
    .date .sp__control {
      width: 70px;
    }

    .month .sp__control {
      width: 160px;
    }

    .year .sp__control {
      width: 90px;
    }
  }
  .wallet-slider-container {
    .walletType {
      width: 285px;
      margin-left: 83px;
    }

    .slick-list {
      overflow: hidden;
      padding: 16px 25px 26px 25px;
    }

    .walletType .items .item {
      margin-right: 12px;
    }
  }
  //Order history Page
  .orderHistory {
    .data-picker-two svg {
      left: 265px;
    }

    .infoTable .row .tablets, {
      width: 39%;
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    width: 93%;

  }
  //Favourites Page
  .favourites, .free-scale, .home, .catalog, singleProduct {
    .productItem {
      margin: 5px auto;
      //width: 95%;
    }

    .itemInfo .icon {
      right: 15px;
    }
  }
  .free-scale .page-links {
    width: 95%;
    margin: 5px auto;
  }
  .singleProduct {
    .singleProductItem .icon.heart {
      right: -55px;
    }

    .singleProductItem .icon.heart {
      right: 0;
    }
  }
  .wallet-slider-container {
    .walletType {
      margin-left: 58px;
    }
  }
  .wallet-slider-container .slick-slider .slick-slide {
    width: 299px !important;
  }
  //Order history Page
  .orderHistory {
    .data-picker-two svg {
      left: 230px;
    }
  }
  //.singleProduct{
  //  .double-slider-container .double-slider{
  //    .nav1 .nav1-element{
  //    padding: 14px 21px 10px 0;
  //    margin-left: 10px;
  //  }
  //    .nav2 .nav2-element{
  //      height: 205px;
  //  }
  //    .slide-border-div {
  //      left: -97px;
  //    }
  //  }
  //}
}

@media screen and (max-width: 375px) {

  .sign-up-first {
    .sign-up-step-container {
      margin-bottom: 24px;
    }
  }
  .wallet-slider-container {
    .walletType {
      margin-left: 62px;
    }
  }
  //Order history Page
  .orderHistory {
    .data-picker-two svg {
      left: 205px;
    }
  }
}
